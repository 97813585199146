import React from "react";

const PandasIntro = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#1F2937", // Fondo oscuro
        color: "#E5E7EB",
        padding: "50px 20px",
        lineHeight: "1.6",
      }}
    >
      {/* Título Principal */}
      <h1 style={{ fontSize: "2.5rem", marginBottom: "20px", color: "#8A2BE2" }}>
        Introducción a Pandas
      </h1>
      <p style={{ fontSize: "1.2rem", marginBottom: "20px" }}>
        **Pandas** es una biblioteca de código abierto para **Python** que permite la manipulación y análisis eficiente de grandes volúmenes de datos. Es una herramienta clave para **científicos de datos**, **analistas** y **desarrolladores** que trabajan con datos tabulares y estructurados.
      </p>
      <p style={{ fontSize: "1.2rem", marginBottom: "20px" }}>
        Desarrollado sobre **NumPy**, Pandas facilita el manejo de datos complejos a través de sus estructuras principales: <strong>Series</strong> y <strong>DataFrames</strong>. Permite operaciones como filtrado, limpieza, transformación y análisis, haciendo que los flujos de trabajo en **Data Science** sean más simples y eficientes.
      </p>

      {/* Contenido */}
      <h2 style={{ fontSize: "2rem", marginBottom: "15px" }}>Contenido:</h2>
      <ul
        style={{
          fontSize: "1.1rem",
          marginBottom: "30px",
          paddingLeft: "20px",
          listStyleType: "square",
        }}
      >
        <li>Qué es Pandas y su importancia en Data Science.</li>
        <li>Cómo instalar Pandas y configurarlo en tu entorno.</li>
        <li>Estructuras de datos principales: <strong>Series</strong> y <strong>DataFrames</strong>.</li>
        <li>Operaciones básicas: Selección, filtrado y agregación.</li>
        <li>Cargar y guardar datos desde archivos: <code>CSV</code>, <code>Excel</code>, <code>JSON</code>.</li>
      </ul>

      {/* Ejemplo Práctico */}
      <h2 style={{ fontSize: "2rem", marginBottom: "15px" }}>Ejemplo Práctico:</h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "15px" }}>
        A continuación, te mostramos cómo usar **Pandas** para cargar y manipular un archivo de datos:
      </p>
      <pre
        style={{
          backgroundColor: "#2D3748",
          color: "#E5E7EB",
          padding: "15px",
          borderRadius: "5px",
          overflowX: "auto",
          marginBottom: "20px",
          textAlign: "left",
        }}
      >
        {`import pandas as pd

# Cargar un archivo CSV
df = pd.read_csv('data.csv')

# Ver las primeras filas del DataFrame
print("Primeras filas:")
print(df.head())

# Filtrar filas donde la edad sea mayor a 30
filtered_df = df[df['edad'] > 30]
print("Filtrado por edad > 30:")
print(filtered_df)

# Seleccionar columnas específicas
selected_columns = df[['nombre', 'edad']]
print("Columnas seleccionadas:")
print(selected_columns)

# Guardar los resultados en un nuevo archivo CSV
filtered_df.to_csv('filtered_data.csv', index=False)
print("Archivo guardado: filtered_data.csv")`}
      </pre>

      {/* Importancia de Pandas */}
      <h2 style={{ fontSize: "2rem", marginBottom: "15px" }}>¿Por qué Pandas es importante?</h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        Pandas es una de las herramientas más utilizadas en **Data Science** por su flexibilidad y facilidad de uso. Su capacidad para manejar datos faltantes, realizar agregaciones y combinar múltiples conjuntos de datos lo hace indispensable para proyectos de análisis.
      </p>

      {/* Recursos */}
      <h2 style={{ fontSize: "2rem", marginBottom: "15px" }}>Recursos Adicionales:</h2>
      <ul
        style={{
          fontSize: "1.1rem",
          paddingLeft: "20px",
          listStyleType: "disc",
        }}
      >
        <li>
          <a
            href="https://pandas.pydata.org/docs/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#8A2BE2" }}
          >
            Documentación oficial de Pandas
          </a>
        </li>
        <li>
          <a
            href="https://pandas.pydata.org/getting_started.html"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#8A2BE2" }}
          >
            Guía de instalación de Pandas
          </a>
        </li>
        <li>
          <a
            href="https://www.w3schools.com/python/pandas/default.asp"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#8A2BE2" }}
          >
            Tutorial interactivo en W3Schools
          </a>
        </li>
      </ul>
    </div>
  );
};

export default PandasIntro;
