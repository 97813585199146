import React from "react";
import { Helmet } from "react-helmet";

const PythonAdvanced = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#1F2937",
        color: "#E5E7EB",
        padding: "50px 20px",
        lineHeight: "1.6",
      }}
    >
      {/* Helmet para SEO */}
      <Helmet>
        <title>Funciones Avanzadas en Python - Curso de Python</title>
        <meta
          name="description"
          content="Explora funciones avanzadas de Python como generadores, decoradores y manejo de excepciones. Aprende a desarrollar aplicaciones robustas y escalables."
        />
      </Helmet>

      {/* Título principal */}
      <h1 style={{ fontSize: "2.5rem", marginBottom: "20px", color: "#8A2BE2" }}>
        Funciones Avanzadas en Python
      </h1>

      {/* Introducción */}
      <p style={{ fontSize: "1.2rem", marginBottom: "20px" }}>
        Explora características avanzadas como generadores, decoradores y más.
        Este módulo está diseñado para llevar tus habilidades de Python al
        siguiente nivel, ayudándote a desarrollar aplicaciones robustas y
        escalables.
      </p>

      {/* Contenido */}
      <h2 style={{ fontSize: "2rem", marginBottom: "10px" }}>Contenido:</h2>
      <ul style={{ fontSize: "1.1rem", marginLeft: "20px" }}>
        <li>
          <b>Uso de generadores para manejar datos grandes:</b>
          <p style={{ marginTop: "5px" }}>
            Los generadores son funciones que producen un valor a la vez,
            permitiendo manejar grandes cantidades de datos de forma eficiente.
          </p>
          <pre
            style={{
              backgroundColor: "#374151",
              padding: "10px",
              borderRadius: "8px",
              overflowX: "auto",
              color: "#E5E7EB",
            }}
          >
            {`# Ejemplo de generador
def contador():
    for i in range(5):
        yield i

for numero in contador():
    print(numero)`}
          </pre>
        </li>

        <li>
          <b>Decoradores: Qué son y cómo se utilizan:</b>
          <p style={{ marginTop: "5px" }}>
            Los decoradores son funciones que permiten modificar el
            comportamiento de otras funciones o métodos.
          </p>
          <pre
            style={{
              backgroundColor: "#374151",
              padding: "10px",
              borderRadius: "8px",
              overflowX: "auto",
              color: "#E5E7EB",
            }}
          >
            {`# Ejemplo de decorador
def decorador(func):
    def envoltura():
        print("Antes de la función")
        func()
        print("Después de la función")
    return envoltura

@decorador
def saludar():
    print("Hola, mundo!")

saludar()`}
          </pre>
        </li>

        <li>
          <b>Manejo de excepciones avanzado:</b>
          <p style={{ marginTop: "5px" }}>
            Aprende a manejar excepciones específicas y a usar la cláusula{" "}
            <code>finally</code> para garantizar que ciertas acciones se
            realicen siempre.
          </p>
          <pre
            style={{
              backgroundColor: "#374151",
              padding: "10px",
              borderRadius: "8px",
              overflowX: "auto",
              color: "#E5E7EB",
            }}
          >
            {`try:
    resultado = 10 / 0
except ZeroDivisionError:
    print("No se puede dividir por cero.")
finally:
    print("Finalización del bloque try.")`}
          </pre>
        </li>

        <li>
          <b>Comprensión de listas y expresiones generadoras:</b>
          <p style={{ marginTop: "5px" }}>
            Simplifica tu código con comprensión de listas para crear listas en
            una sola línea.
          </p>
          <pre
            style={{
              backgroundColor: "#374151",
              padding: "10px",
              borderRadius: "8px",
              overflowX: "auto",
              color: "#E5E7EB",
            }}
          >
            {`# Comprensión de listas
numeros = [x for x in range(10) if x % 2 == 0]
print(numeros)  # [0, 2, 4, 6, 8]`}
          </pre>
        </li>
      </ul>

      {/* Recursos adicionales */}
      <h2 style={{ fontSize: "2rem", marginBottom: "10px" }}>Recursos:</h2>
      <ul style={{ fontSize: "1.1rem", marginLeft: "20px" }}>
        <li>
          <a
            href="https://docs.python.org/3/howto/functional.html"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#8A2BE2" }}
          >
            Documentación oficial sobre programación funcional en Python
          </a>
        </li>
        <li>
          <a
            href="https://realpython.com/primer-on-python-decorators/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#8A2BE2" }}
          >
            Real Python - Guía de decoradores
          </a>
        </li>
        <li>
          <a
            href="https://www.w3schools.com/python/python_generators.asp"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#8A2BE2" }}
          >
            W3Schools - Generadores en Python
          </a>
        </li>
      </ul>

      {/* Mensaje final */}
      <p style={{ marginTop: "20px", fontSize: "1.1rem" }}>
        ¡Explora estas funciones avanzadas y lleva tus proyectos en Python al
        siguiente nivel! Practica con los ejemplos y consulta los recursos
        adicionales para profundizar.
      </p>
    </div>
  );
};

export default PythonAdvanced;
