import React from "react";

function SavedRoutines({ routines, loadRoutine, deleteRoutine }) {
  if (routines.length === 0) return null;

  return (
    <div className="mb-4">
      <h3 className="text-lg font-semibold mb-2 text-white">
        Rutinas Guardadas:
      </h3>
      {routines.map((routine) => (
        <div
          key={routine.name}
          className="flex justify-between items-center p-3 bg-gray-700 rounded-lg mb-2"
        >
          <span className="font-medium text-white">{routine.name}</span>
          <div className="flex space-x-2">
            <button
              onClick={() => loadRoutine(routine)}
              className="bg-blue-600 hover:bg-blue-700 text-white py-1 px-3 rounded-lg text-sm"
            >
              Cargar
            </button>
            <button
              onClick={() => deleteRoutine(routine.name)}
              className="bg-red-600 hover:bg-red-700 text-white py-1 px-3 rounded-lg text-sm"
            >
              Eliminar
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SavedRoutines;
