// src/components/Heracles/CreateTaskForm.jsx
import React, { useState } from 'react';

const CreateTaskForm = ({ onAddTask }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [responsable, setResponsable] = useState('');
  const [priority, setPriority] = useState('Media');
  const [dueDate, setDueDate] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (title.trim() === '') return;
    onAddTask(title, description, responsable, priority, dueDate);
    setTitle('');
    setDescription('');
    setResponsable('');
    setPriority('Media');
    setDueDate('');
  };

  const formStyle = {
    marginBottom: '20px',
    padding: '20px',
    backgroundColor: '#2d2d2d',
    borderRadius: '4px',
    maxWidth: '600px',
    margin: 'auto',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.5)',
    color: '#fff',
  };

  const inputStyle = {
    padding: '10px',
    width: '100%',
    marginBottom: '12px',
    borderRadius: '4px',
    border: '1px solid #555',
    fontSize: '16px',
    color: '#eee',
    backgroundColor: '#424242',
  };

  const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: '#7e57c2',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  };

  return (
    <form onSubmit={handleSubmit} style={formStyle}>
      <h2>Agregar Nueva Tarea</h2>
      <input
        type="text"
        value={title}
        placeholder="Título de la tarea"
        onChange={(e) => setTitle(e.target.value)}
        style={inputStyle}
      />
      <input
        type="text"
        value={description}
        placeholder="Descripción de la tarea"
        onChange={(e) => setDescription(e.target.value)}
        style={inputStyle}
      />
      <input
        type="text"
        value={responsable}
        placeholder="Responsable de la tarea"
        onChange={(e) => setResponsable(e.target.value)}
        style={inputStyle}
      />
      <select
        value={priority}
        onChange={(e) => setPriority(e.target.value)}
        style={inputStyle}
      >
        <option value="Baja">Baja</option>
        <option value="Media">Media</option>
        <option value="Alta">Alta</option>
      </select>
      <input
        type="date"
        value={dueDate}
        onChange={(e) => setDueDate(e.target.value)}
        style={inputStyle}
      />
      <button type="submit" style={buttonStyle}>
        Agregar
      </button>
    </form>
  );
};

export default CreateTaskForm;
