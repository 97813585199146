import React from 'react';
import { Helmet } from 'react-helmet';

const IntroToNpm = () => {
  return (
    <div style={{ padding: '20px', backgroundColor: '#1F2937', color: '#E5E7EB' }}>
      {/* Helmet para SEO */}
      <Helmet>
        <title>Introducción a NPM - Aprende lo básico</title>
        <meta
          name="description"
          content="Descubre los conceptos básicos de NPM, cómo instalarlo y usarlo para gestionar dependencias en proyectos Node.js."
        />
        <meta
          name="keywords"
          content="NPM, Node.js, gestor de paquetes, instalación de NPM, tutorial de NPM"
        />
        <meta name="author" content="Fifty Academy" />
      </Helmet>

      {/* Contenido del componente */}
      <h1 style={{ fontSize: '2.5rem', marginBottom: '20px' }}>Introducción a NPM</h1>
      <p style={{ fontSize: '1.2rem', marginBottom: '20px', lineHeight: '1.6' }}>
        Aprende los conceptos básicos de NPM y cómo instalarlo. NPM (Node Package Manager) es una herramienta esencial 
        para gestionar paquetes y dependencias en proyectos basados en Node.js. Es ampliamente utilizado en la comunidad 
        de desarrollo web.
      </p>
      <h2 style={{ fontSize: '2rem', marginBottom: '15px' }}>¿Qué es NPM?</h2>
      <p style={{ fontSize: '1rem', marginBottom: '15px', lineHeight: '1.5' }}>
        NPM es el gestor de paquetes por defecto para Node.js. Permite instalar, compartir y administrar bibliotecas y 
        herramientas que facilitan el desarrollo de aplicaciones modernas.
      </p>
      <h2 style={{ fontSize: '2rem', marginBottom: '15px' }}>Instalación</h2>
      <p style={{ fontSize: '1rem', marginBottom: '10px', lineHeight: '1.5' }}>
        Antes de empezar, necesitas instalar Node.js, que incluye NPM por defecto. Sigue estos pasos:
      </p>
      <ol style={{ fontSize: '1rem', marginBottom: '20px', lineHeight: '1.6', paddingLeft: '20px' }}>
        <li>Visita la página oficial de Node.js: <a href="https://nodejs.org" target="_blank" rel="noopener noreferrer" style={{ color: '#8A2BE2' }}>nodejs.org</a></li>
        <li>Descarga e instala la versión LTS recomendada para tu sistema operativo.</li>
        <li>Abre una terminal y verifica la instalación con los comandos:
          <ul style={{ marginTop: '10px', listStyle: 'circle', paddingLeft: '20px' }}>
            <li><code>node -v</code>: para verificar la versión de Node.js.</li>
            <li><code>npm -v</code>: para verificar la versión de NPM.</li>
          </ul>
        </li>
      </ol>
      <h2 style={{ fontSize: '2rem', marginBottom: '15px' }}>Primeros pasos con NPM</h2>
      <p style={{ fontSize: '1rem', marginBottom: '20px', lineHeight: '1.5' }}>
        Una vez que NPM está instalado, puedes usarlo para:
      </p>
      <ul style={{ fontSize: '1rem', marginBottom: '20px', lineHeight: '1.6', listStyle: 'square', paddingLeft: '20px' }}>
        <li>Instalar paquetes: <code>npm install [nombre-del-paquete]</code>.</li>
        <li>Crear un nuevo proyecto: <code>npm init</code>.</li>
        <li>Ejecutar scripts definidos en <code>package.json</code>: <code>npm run [script]</code>.</li>
      </ul>
      <p style={{ fontSize: '1rem', marginBottom: '20px', lineHeight: '1.5' }}>
        Ahora estás listo para explorar el mundo de NPM y mejorar tu flujo de trabajo como desarrollador.
      </p>
    </div>
  );
};

export default IntroToNpm;
