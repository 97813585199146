import React, { useState, useEffect } from "react";

// Conjunto de cartas (pares de símbolos).
// Puedes añadir más o cambiar los símbolos.
const symbols = ["🍎", "🍌", "🍇", "🍒", "🍑", "🍓"];

// Función para duplicar y mezclar el array de símbolos
function shuffleCards() {
  const duplicated = [...symbols, ...symbols] // Duplicamos
    .sort(() => Math.random() - 0.5) // Mezclamos aleatoriamente
    .map((symbol, index) => ({
      id: index,
      symbol,
      flipped: false,
      matched: false,
    }));
  return duplicated;
}

function MemoryMatchGame() {
  const [cards, setCards] = useState([]);
  const [firstPick, setFirstPick] = useState(null);
  const [secondPick, setSecondPick] = useState(null);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    // Inicializa el juego al montar el componente
    const newDeck = shuffleCards();
    setCards(newDeck);
  }, []);

  useEffect(() => {
    // Verifica coincidencia cuando hay dos cartas seleccionadas
    if (firstPick && secondPick) {
      setDisabled(true);
      if (firstPick.symbol === secondPick.symbol) {
        // Si coinciden, las marcamos como encontradas
        setCards((prevCards) =>
          prevCards.map((card) =>
            card.symbol === firstPick.symbol ? { ...card, matched: true } : card
          )
        );
        resetTurn();
      } else {
        // No coinciden, se voltean de nuevo
        setTimeout(() => {
          setCards((prevCards) =>
            prevCards.map((card) =>
              card.id === firstPick.id || card.id === secondPick.id
                ? { ...card, flipped: false }
                : card
            )
          );
          resetTurn();
        }, 1000);
      }
    }
  }, [firstPick, secondPick]);

  const handleCardClick = (card) => {
    if (disabled) return;
    if (card.flipped || card.matched) return;

    if (!firstPick) {
      // Seleccionamos la primera carta
      setFirstPick({ ...card, flipped: true });
      setCards((prev) =>
        prev.map((c) => (c.id === card.id ? { ...c, flipped: true } : c))
      );
    } else if (!secondPick) {
      // Seleccionamos la segunda carta
      setSecondPick({ ...card, flipped: true });
      setCards((prev) =>
        prev.map((c) => (c.id === card.id ? { ...c, flipped: true } : c))
      );
    }
  };

  const resetTurn = () => {
    setFirstPick(null);
    setSecondPick(null);
    setDisabled(false);
  };

  const restartGame = () => {
    setFirstPick(null);
    setSecondPick(null);
    setDisabled(false);
    setCards(shuffleCards());
  };

  const allMatched = cards.length > 0 && cards.every((card) => card.matched);

  return (
    <div
      className="memory-game-container"
      style={{ textAlign: "center", fontFamily: "sans-serif", padding: "20px" }}
    >
      <h1>Juego de Memoria</h1>
      <button onClick={restartGame} style={{ margin: "10px" }}>
        Reiniciar
      </button>
      {allMatched && <h2>¡Felicidades! Has encontrado todos los pares.</h2>}

      <div
        className="cards-grid"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(6, 50px)",
          gridGap: "10px",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        {cards.map((card) => (
          <div
            key={card.id}
            onClick={() => handleCardClick(card)}
            style={{
              width: "50px",
              height: "50px",
              fontSize: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #ccc",
              borderRadius: "8px",
              cursor: "pointer",
              background: card.flipped || card.matched ? "#fff" : "#aaa",
              transition: "transform 0.3s",
            }}
          >
            {card.flipped || card.matched ? card.symbol : ""}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MemoryMatchGame;
