import React, { useState, useEffect, useCallback, useRef } from "react";

const NUM_POINTS = 55;
const WIDTH = 800;
const HEIGHT = 600;
const MIN_DIST = 25;
const LINE_SAMPLING_DIST = 5; // distancia mínima para no sobresaturar de puntos

function distance(a, b) {
  return Math.sqrt((a.x - b.x) * (a.x - b.x) + (a.y - b.y) * (a.y - b.y));
}

function generatePoints() {
  const points = [];
  for (let i = 1; i <= NUM_POINTS; i++) {
    let attempts = 0;
    let candidate = { num: i, x: 0, y: 0 };
    let isTooClose;
    do {
      const x = Math.random() * (WIDTH - 40) + 20;
      const y = Math.random() * (HEIGHT - 40) + 20;
      candidate = { num: i, x, y };
      attempts++;
      if (attempts > 1000) {
        break;
      }
      isTooClose = false;
      for (let pt of points) {
        if (distance(pt, candidate) < MIN_DIST) {
          isTooClose = true;
          break;
        }
      }
    } while (isTooClose);

    points.push(candidate);
  }
  return points;
}

// Checa si dos segmentos (p1->p2 y p3->p4) se intersectan, excluyendo casos en que solo comparten extremos.
function segmentsIntersect(p1, p2, p3, p4) {
  const ccw = (A, B, C) =>
    (C.y - A.y) * (B.x - A.x) > (B.y - A.y) * (C.x - A.x);
  const intersect =
    ccw(p1, p3, p4) !== ccw(p2, p3, p4) && ccw(p1, p2, p3) !== ccw(p1, p2, p4);

  if (!intersect) return false;

  // Verificar si comparten endpoints exactos: eso no se considera intersección "prohibida"
  const samePoint = (X, Y) =>
    Math.abs(X.x - Y.x) < 0.0001 && Math.abs(X.y - Y.y) < 0.0001;

  if (
    samePoint(p1, p3) ||
    samePoint(p1, p4) ||
    samePoint(p2, p3) ||
    samePoint(p2, p4)
  ) {
    // Comparten un extremo, no lo consideramos intersección no válida
    return false;
  }

  return true;
}

function pathIntersects(path, lines) {
  // path es array de puntos {x,y}, lines es array de paths
  for (let i = 0; i < path.length - 1; i++) {
    const segStart = path[i];
    const segEnd = path[i + 1];
    for (let line of lines) {
      for (let j = 0; j < line.length - 1; j++) {
        const lStart = line[j];
        const lEnd = line[j + 1];
        if (segmentsIntersect(segStart, segEnd, lStart, lEnd)) {
          return true;
        }
      }
    }
  }
  return false;
}

function ConnectNumbersGame() {
  const [points, setPoints] = useState([]);
  const [currentNumber, setCurrentNumber] = useState(1);
  const [lines, setLines] = useState([]); // cada línea es un array de puntos {x,y}
  const [dragging, setDragging] = useState(false);
  const [dragStartPoint, setDragStartPoint] = useState(null);
  const [currentPath, setCurrentPath] = useState([]);
  const [lastPathPoint, setLastPathPoint] = useState(null);

  const containerRef = useRef(null);

  useEffect(() => {
    const pts = generatePoints();
    setPoints(pts);
  }, []);

  const handleMouseDown = useCallback(
    (p) => {
      if (p.num === currentNumber && !dragging) {
        setDragging(true);
        setDragStartPoint(p);
        setCurrentPath([{ x: p.x + 10, y: p.y + 10 }]);
        setLastPathPoint({ x: p.x + 10, y: p.y + 10 });
      }
    },
    [currentNumber, dragging]
  );

  const handleMouseMove = (e) => {
    if (!dragging || !lastPathPoint) return;
    const rect = containerRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const newPoint = { x, y };

    if (distance(lastPathPoint, newPoint) > LINE_SAMPLING_DIST) {
      setCurrentPath((prev) => [...prev, newPoint]);
      setLastPathPoint(newPoint);
    }
  };

  const handleMouseUp = useCallback(
    (e) => {
      if (!dragging || !dragStartPoint || currentPath.length < 2) {
        setDragging(false);
        setCurrentPath([]);
        setLastPathPoint(null);
        return;
      }

      setDragging(false);

      const rect = containerRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      const nextNumber = currentNumber + 1;
      const nextPoint = points.find((pt) => pt.num === nextNumber);
      if (!nextPoint) {
        setCurrentPath([]);
        setLastPathPoint(null);
        return;
      }

      const distToNext = distance(nextPoint, { x: x - 10, y: y - 10 });
      if (distToNext < 15) {
        const finalPoint = { x: nextPoint.x + 10, y: nextPoint.y + 10 };
        const finalPath = [...currentPath, finalPoint];

        if (pathIntersects(finalPath, lines)) {
          alert(
            "La línea dibujada se cruza con otra línea. Intenta otra ruta."
          );
          setCurrentPath([]);
          setLastPathPoint(null);
          return;
        }

        setLines((prev) => [...prev, finalPath]);
        setCurrentNumber((n) => n + 1);
        setCurrentPath([]);
        setLastPathPoint(null);
      } else {
        setCurrentPath([]);
        setLastPathPoint(null);
      }
    },
    [dragging, dragStartPoint, currentPath, points, lines, currentNumber]
  );

  const isGameWon = currentNumber > NUM_POINTS;

  const containerStyle = {
    position: "relative",
    width: WIDTH + "px",
    height: HEIGHT + "px",
    border: "1px solid #ccc",
    margin: "20px auto",
    background: "#222",
    overflow: "hidden",
    color: "#fff",
    fontFamily: "sans-serif",
  };

  const pointStyle = (p) => {
    let bg = "#555";
    let border = "1px solid #999";
    if (p.num === 1) {
      bg = "linear-gradient(45deg, #f00, #800)";
      border = "1px solid #f33";
    }
    if (p.num === currentNumber + 1) {
      bg = "#4444ff";
      border = "1px solid #8888ff";
    }
    return {
      position: "absolute",
      left: p.x + "px",
      top: p.y + "px",
      width: "20px",
      height: "20px",
      background: bg,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: p.num === currentNumber ? "crosshair" : "default",
      color: "#fff",
      fontSize: "12px",
      userSelect: "none",
      border: border,
    };
  };

  const lineContainerStyle = {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    pointerEvents: "none",
  };

  return (
    <div
      style={{
        textAlign: "center",
        padding: "20px",
        background: "#333",
        minHeight: "100vh",
        color: "#fff",
        boxSizing: "border-box",
      }}
    >
      <h1>Conecta del 1 al 100 sin que las líneas se crucen</h1>
      {isGameWon && <h2>¡Felicidades! Has conectado todos los puntos.</h2>}
      <p>
        Punto actual a conectar:{" "}
        {currentNumber <= NUM_POINTS ? currentNumber : "Completado"}
      </p>
      <div
        style={containerStyle}
        ref={containerRef}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <div style={lineContainerStyle}>
          <svg width="100%" height="100%">
            {lines.map((linePath, i) => (
              <polyline
                key={i}
                points={linePath.map((p) => `${p.x},${p.y}`).join(" ")}
                fill="none"
                stroke="#800080"
                strokeWidth="2"
              />
            ))}
            {dragging && currentPath.length > 1 && (
              <polyline
                points={currentPath.map((p) => `${p.x},${p.y}`).join(" ")}
                fill="none"
                stroke="#800080"
                strokeWidth="2"
                strokeDasharray="4"
              />
            )}
          </svg>
        </div>
        {points.map((p) => (
          <div
            key={p.num}
            style={pointStyle(p)}
            onMouseDown={() => handleMouseDown(p)}
          >
            {p.num}
          </div>
        ))}
      </div>
      <button
        style={{
          marginTop: "20px",
          padding: "10px",
          cursor: "pointer",
          border: "none",
          background: "#555",
          color: "#fff",
          borderRadius: "4px",
        }}
        onClick={() => {
          const pts = generatePoints();
          setPoints(pts);
          setLines([]);
          setCurrentNumber(1);
          setCurrentPath([]);
          setLastPathPoint(null);
        }}
      >
        Reiniciar
      </button>
    </div>
  );
}

export default ConnectNumbersGame;
