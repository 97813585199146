import exercises from "../exercises";

const modes = {
  standard: {
    name: "Entrenamiento Estándar",
    exercises: exercises,
    restTime: 15,
    description:
      "Un entrenamiento estándar con 30 segundos por ejercicio y 15 segundos de descanso activo entre ejercicios. Ideal para sesiones de ritmo moderado.",
  },
  tabata: {
    name: "Entrenamiento Tabata",
    exercises: exercises.map((exercise) => ({ ...exercise, duration: 20 })),
    restTime: 10,
    description:
      "Entrenamiento de alta intensidad con 20 segundos de trabajo y 10 segundos de descanso activo. Perfecto para mejorar resistencia y fuerza en menos tiempo.",
  },
  custom: {
    name: "Entrenamiento Personalizado",
    exercises: exercises.map((exercise) => ({ ...exercise, duration: 45 })),
    restTime: 20,
    description:
      "Un entrenamiento personalizado con 45 segundos por ejercicio y 20 segundos de descanso activo. Ideal para entrenamientos a tu propio ritmo.",
  },
};

export default modes;
