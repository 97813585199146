import React from "react";
import TeamOverview from "./TeamOverview";

const TeamApp = () => (
  <div className="p-8">
    {/* Título principal */}
    <h1 className="text-4xl font-bold text-center mb-4">
      Conoce a Nuestro Equipo
    </h1>

    {/* Descripción o introducción */}
    <p className="text-lg text-center text-gray-700 mb-8">
      Presentamos a la organización: un grupo apasionado, innovador y
      comprometido con brindar soluciones de primer nivel. Nuestro equipo está
      formado por profesionales en diversas áreas, cada uno aportando su talento
      y experiencia para alcanzar el éxito en cada proyecto.
    </p>

    {/* Componente que muestra la vista general de los miembros del equipo */}
    <TeamOverview />
  </div>
);

export default TeamApp;
