import React from "react";
import { Helmet } from "react-helmet";

const NpmTesting = () => {
  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#1F2937", // Fondo oscuro
        color: "#E5E7EB", // Texto claro
        minHeight: "100vh",
        lineHeight: "1.6",
      }}
    >
      {/* Helmet para SEO */}
      <Helmet>
        <title>Pruebas con NPM - Configuración y Ejecución</title>
        <meta
          name="description"
          content="Aprende cómo utilizar NPM para configurar herramientas de testing como Jest y Mocha, y ejecutar pruebas con npm test."
        />
      </Helmet>

      {/* Encabezado principal */}
      <h1 style={{ fontSize: "2.5rem", marginBottom: "20px", color: "#8A2BE2" }}>
        Pruebas con NPM
      </h1>

      {/* Párrafos introductorios */}
      <p style={{ fontSize: "1.2rem", marginBottom: "15px" }}>
        Descubre cómo NPM puede ayudarte a configurar herramientas de testing.
      </p>
      <p style={{ fontSize: "1.2rem", marginBottom: "30px" }}>
        Las pruebas aseguran que tu aplicación funcione correctamente y permiten
        detectar errores a tiempo.
      </p>

      {/* Lista de pasos */}
      <h2 style={{ fontSize: "2rem", marginBottom: "10px", color: "#E5E7EB" }}>
        Pasos para configurar y ejecutar pruebas con NPM
      </h2>
      <ul style={{ fontSize: "1.1rem", marginLeft: "20px" }}>
        <li style={{ marginBottom: "10px" }}>
          <b>Instalación de Jest o Mocha:</b> Usa el comando:
          <pre
            style={{
              backgroundColor: "#374151",
              color: "#E5E7EB",
              padding: "10px",
              borderRadius: "8px",
            }}
          >
            npm install --save-dev jest
          </pre>
          O, si prefieres Mocha:
          <pre
            style={{
              backgroundColor: "#374151",
              color: "#E5E7EB",
              padding: "10px",
              borderRadius: "8px",
            }}
          >
            npm install --save-dev mocha
          </pre>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <b>Configurar scripts de test en package.json:</b> Agrega lo siguiente
          a tu archivo <code>package.json</code>:
          <pre
            style={{
              backgroundColor: "#374151",
              color: "#E5E7EB",
              padding: "10px",
              borderRadius: "8px",
            }}
          >
            "scripts": &#123;
              <br />
              &nbsp;&nbsp;"test": "jest",
              <br />
              &nbsp;&nbsp;"test:mocha": "mocha"
              <br />
            &#125;
          </pre>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <b>Ejecutar pruebas con npm test:</b> Corre las pruebas usando el
          comando:
          <pre
            style={{
              backgroundColor: "#374151",
              color: "#E5E7EB",
              padding: "10px",
              borderRadius: "8px",
            }}
          >
            npm test
          </pre>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <b>Configurar herramientas adicionales como ESLint o Prettier:</b>
          Estas herramientas ayudan a mantener la calidad del código:
          <pre
            style={{
              backgroundColor: "#374151",
              color: "#E5E7EB",
              padding: "10px",
              borderRadius: "8px",
            }}
          >
            npm install --save-dev eslint prettier
          </pre>
        </li>
      </ul>

      {/* Ejemplo final */}
      <h2 style={{ fontSize: "2rem", marginBottom: "10px", color: "#E5E7EB" }}>
        Ejemplo completo en package.json
      </h2>
      <pre
        style={{
          backgroundColor: "#374151",
          padding: "15px",
          borderRadius: "8px",
          overflowX: "auto",
          color: "#E5E7EB",
        }}
      >
        {`{
  "name": "mi-proyecto",
  "version": "1.0.0",
  "scripts": {
    "test": "jest",
    "lint": "eslint .",
    "format": "prettier --write ."
  },
  "devDependencies": {
    "jest": "^27.0.0",
    "eslint": "^7.0.0",
    "prettier": "^2.0.0"
  }
}`}
      </pre>

      {/* Conclusión */}
      <p style={{ marginTop: "20px", fontSize: "1.1rem" }}>
        Ahora puedes utilizar NPM para configurar y ejecutar pruebas de manera
        sencilla. Herramientas como Jest, Mocha, ESLint y Prettier mejorarán la
        calidad y estabilidad de tus proyectos.
      </p>
    </div>
  );
};

export default NpmTesting;
