import React, { useState, useEffect } from "react";
import { FaDiscord, FaGraduationCap } from "react-icons/fa"; // Importa íconos necesarios
import {
  Dumbbell,
  Gamepad2,
  Music,
  Home,
  Newspaper,
  Gamepad,
  Store,
} from "lucide-react"; // Agregamos Gamepad

const Sidebar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [sidebarExpanded, setSidebarExpanded] = useState(true); // Expandido por defecto en desktop

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      setSidebarExpanded(!mobile); // Colapsado en móvil por defecto
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Trigger on initial render
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setSidebarExpanded(!sidebarExpanded);
  };

  return (
    <div
      className={`fixed top-0 left-0 h-full bg-gray-800 p-4 transition-all duration-300 z-50 ${
        sidebarExpanded ? "w-64" : "w-16"
      }`}
    >
      <div className="flex flex-col items-center space-y-6">
        <a
          href="/"
          className="text-white hover:text-purple-400 transition duration-300 flex items-center"
        >
          <Home size={32} />
          {sidebarExpanded && <span className="ml-2 text-white">Inicio</span>}
        </a>

        <a
          href="/esports-info"
          className="text-white hover:text-purple-400 transition duration-300 flex items-center"
        >
          <Gamepad2 size={32} />
          {sidebarExpanded && <span className="ml-2 text-white">Esports</span>}
        </a>
        <a
          href="/records-info"
          className="text-white hover:text-purple-400 transition duration-300 flex items-center"
        >
          <Music size={32} />
          {sidebarExpanded && <span className="ml-2 text-white">Records</span>}
        </a>
        <a
          href="/news-info"
          className="text-white hover:text-purple-400 transition duration-300 flex items-center"
        >
          <Newspaper size={32} />
          {sidebarExpanded && <span className="ml-2 text-white">Noticias</span>}
        </a>
        <a
          href="/training"
          className="text-white hover:text-purple-400 transition duration-300 flex items-center"
        >
          <Dumbbell size={32} />
          {sidebarExpanded && <span className="ml-2 text-white">Fitness</span>}
        </a>
        <a
          href="/academy"
          className="text-white hover:text-purple-400 transition duration-300 flex items-center"
        >
          <FaGraduationCap size={32} />
          {sidebarExpanded && <span className="ml-2 text-white">Academia</span>}
        </a>

        {/* Usamos el ícono Gamepad para representar el joystick en la sección Games */}
        <a
          href="/HomeGames"
          className="text-white hover:text-purple-400 transition duration-300 flex items-center"
        >
          <Gamepad size={32} />
          {sidebarExpanded && <span className="ml-2 text-white">Games</span>}
        </a>


          {/* Usamos el ícono Gamepad para representar el joystick en la sección Games */}
          <a
          href="/Marketplace"
          className="text-white hover:text-purple-400 transition duration-300 flex items-center"
        >
          <Store size={32} />
          {sidebarExpanded && <span className="ml-2 text-white">Tienda</span>}
        </a>

        {/* Botón que lleva a la comunidad de Discord */}
        <a
          href="https://discord.gg/AH6pXzYs2h"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white hover:text-purple-400 transition duration-300 flex items-center"
        >
          <FaDiscord size={32} />
          {sidebarExpanded && <span className="ml-2 text-white">Discord</span>}
        </a>

        {isMobile && (
          <button onClick={toggleSidebar} className="text-white mt-auto">
            {sidebarExpanded ? "←" : "→"}
          </button>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
