import React from "react";
import { Helmet } from "react-helmet";

const NpmAdvanced = () => {
  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#1F2937", // Fondo oscuro
        color: "#E5E7EB", // Texto claro
        minHeight: "100vh",
        lineHeight: "1.6",
      }}
    >
      {/* Helmet para SEO */}
      <Helmet>
        <title>Funciones avanzadas de NPM - Workspaces y Hooks</title>
        <meta
          name="description"
          content="Explora características avanzadas de NPM como workspaces, hooks preinstall/postinstall y estrategias de optimización para grandes proyectos."
        />
      </Helmet>

      {/* Encabezado principal */}
      <h1 style={{ fontSize: "2.5rem", marginBottom: "20px", color: "#8A2BE2" }}>
        Funciones avanzadas de NPM
      </h1>

      {/* Párrafos introductorios */}
      <p style={{ fontSize: "1.2rem", marginBottom: "15px" }}>
        Aprende a utilizar funciones avanzadas como <b>workspaces</b>,{" "}
        <b>hooks</b> y estrategias de optimización en NPM.
      </p>
      <p style={{ fontSize: "1.2rem", marginBottom: "30px" }}>
        Estas herramientas son clave para mejorar la gestión de dependencias en
        grandes proyectos.
      </p>

      {/* Lista de funciones avanzadas */}
      <h2 style={{ fontSize: "2rem", marginBottom: "10px", color: "#E5E7EB" }}>
        Características avanzadas de NPM
      </h2>
      <ul style={{ fontSize: "1.1rem", marginLeft: "20px" }}>
        <li style={{ marginBottom: "20px" }}>
          <b>Configuración de workspaces en NPM:</b>
          <p>
            Los workspaces permiten manejar múltiples proyectos (monorepos) en
            una misma estructura.
          </p>
          <pre
            style={{
              backgroundColor: "#374151",
              padding: "10px",
              borderRadius: "8px",
              overflowX: "auto",
            }}
          >
            {`// package.json
{
  "name": "mi-monorepo",
  "workspaces": ["packages/*"]
}`}
          </pre>
          <p>
            Ejecuta <code>npm install</code> para instalar todas las
            dependencias de los proyectos dentro de <code>packages/*</code>.
          </p>
        </li>

        <li style={{ marginBottom: "20px" }}>
          <b>Hooks preinstall y postinstall:</b>
          <p>
            Puedes ejecutar scripts automáticamente antes o después de instalar
            dependencias.
          </p>
          <pre
            style={{
              backgroundColor: "#374151",
              padding: "10px",
              borderRadius: "8px",
              overflowX: "auto",
            }}
          >
            {`"scripts": {
  "preinstall": "echo 'Antes de instalar dependencias'",
  "postinstall": "echo 'Después de instalar dependencias'"
}`}
          </pre>
        </li>

        <li style={{ marginBottom: "20px" }}>
          <b>Optimización de dependencias con npm prune:</b>
          <p>
            Elimina paquetes no utilizados para mantener tu proyecto limpio y
            optimizado.
          </p>
          <pre
            style={{
              backgroundColor: "#374151",
              padding: "10px",
              borderRadius: "8px",
              overflowX: "auto",
            }}
          >
            npm prune
          </pre>
        </li>

        <li style={{ marginBottom: "20px" }}>
          <b>Estrategias para proyectos monorepo:</b>
          <p>
            Utiliza NPM junto con workspaces y herramientas como{" "}
            <code>lerna</code> para gestionar múltiples paquetes de manera
            eficiente.
          </p>
          <pre
            style={{
              backgroundColor: "#374151",
              padding: "10px",
              borderRadius: "8px",
              overflowX: "auto",
            }}
          >
            npm install -g lerna
          </pre>
          <p>
            Ejecuta <code>lerna init</code> para inicializar un monorepo con
            NPM.
          </p>
        </li>
      </ul>

      {/* Conclusión */}
      <h2 style={{ fontSize: "2rem", marginBottom: "10px", color: "#E5E7EB" }}>
        Conclusión
      </h2>
      <p style={{ fontSize: "1.1rem", marginBottom: "20px" }}>
        Las funciones avanzadas de NPM, como <b>workspaces</b>, <b>hooks</b> y{" "}
        <b>npm prune</b>, son herramientas poderosas que facilitan la gestión de
        proyectos complejos y optimizan tu flujo de trabajo.
      </p>
    </div>
  );
};

export default NpmAdvanced;
