// src/routes.js
import React from "react";
import HomePage from "./components/home/HomePage";
import SSLDocumentation from "./documents/SSLDocumentation";
import FiftyInteractiveUniverse from "./components/home/FiftyUniverse";
import EsportsInfo from "./components/FiftySports/EsportsInfo";
import RecordsInfo from "./components/FiftyRecords/RecordsInfo";
import NewsInfo from "./components/FiftyNews/NewsInfo";
import NNdNandoPage from "./components/FiftyRecords/artists/nNd_Nando/nNd_Nando";
import Dashboard from "./components/FiftySports/ClashRoyale/Dashboard";
import FormClanContact from "./components/FiftySports/ClashRoyale/fromClanContact";
import TrainingApp from "./components/FiftyTraining/app";
import NotFound from "./components/common/NotFound"; // Componente de 404
import PokemonTable from "./components/FiftySports/Pokemon/CaptureMewProgress";
import NotionTable from "./Notion/NotionTable";
import Academy from "./components/FiftyAcademy/Academy";
import ProfilePage from "./components/common/ProfilePage";
import TeamApp from "./components/FiftyTeam/app";
import { TasksPage } from "./components/Heracles";

//Rutas de NPM
import NpmHome from "./components/FiftyAcademy/LearningPaths/Npm/NpmHome";
import IntroToNpm from "./components/FiftyAcademy/LearningPaths/Npm/IntroToNpm";
import NpmCommandsPage from "./components/FiftyAcademy/LearningPaths/Npm/NpmCommandsPage";
import PackageJson from "./components/FiftyAcademy/LearningPaths/Npm/PackageJson";
import GlobalVsLocal from "./components/FiftyAcademy/LearningPaths/Npm/GlobalVsLocal";
import NpmTesting from "./components/FiftyAcademy/LearningPaths/Npm/NpmTesting";
import NpmAdvanced from "./components/FiftyAcademy/LearningPaths/Npm/NpmAdvanced";

//Rutas de Python
import PythonHome from "./components/FiftyAcademy/LearningPaths/Python/PythonHome";
import IntroToPython from "./components/FiftyAcademy/LearningPaths/Python/IntroToPython";
import PythonSyntax from "./components/FiftyAcademy/LearningPaths/Python/PythonSyntax";
import PythonAdvanced from "./components/FiftyAcademy/LearningPaths/Python/PythonAdvanced";

//Rutas de Pandas

import PandasHome from "./components/FiftyAcademy/LearningPaths/Pandas/PandasHome";
import PandasIntro from "./components/FiftyAcademy/LearningPaths/Pandas/PandasIntro";
import DataFrames from "./components/FiftyAcademy/LearningPaths/Pandas/DataFrames";
import AdvancedPandas from "./components/FiftyAcademy/LearningPaths/Pandas/AdvancedPandas";

//Rutas de HomeGames
import HomeGames from "./components/FiftyGames/HomeGames";
//Rutas de MemoryMatchGame
import MemoryMatchGame from "./components/FiftyGames/MemoryMatch/MemoryMatchGame";
//Rutas de LogicSwitchGame
import LogicSwitchGame from "./components/FiftyGames/LogicSwitchGame/LogicSwitchGame";
//Rutas de LogicSwitchGame
import ConnectNumbersGame from "./components/FiftyGames/ConnectNumbersGame/ConnectNumbersGame";

//Rutas de Market
import MarketHome from "./components/FiftyMarket/MarketHome";

// Nuevos imports para OAuth2
import OAuthLogin from "./components/OAuth2/OAuthLogin";
import OAuthCallback from "./components/OAuth2/OAuthCallback";
// Importa OAuthKYC
import OAuthKYC from "./components/OAuth2/OAuthKYC";

// Obtén el Guild ID desde la variable de entorno
const REQUIRED_GUILD_ID = process.env.REACT_APP_DISCORD_GUILD_ID;

const routes = [
  { path: "/", component: HomePage },
  {
    path: "/documents/SSL",
    component: (props) => (
      <OAuthKYC requiredGuildId={REQUIRED_GUILD_ID}>
        <SSLDocumentation {...props} />
      </OAuthKYC>
    ),
  },
  { path: "fiftyuniverse", component: FiftyInteractiveUniverse },
  { path: "/team", component: TeamApp },
  { path: "esports-info", component: EsportsInfo },
  { path: "records-info", component: RecordsInfo },
  { path: "news-info", component: NewsInfo },
  { path: "Nando", component: NNdNandoPage },
  { path: "ClashRoyale/Dashboard", component: Dashboard },
  { path: "contact-clans", component: FormClanContact },
  { path: "training", component: TrainingApp },
  { path: "CaptureMewProgress", component: PokemonTable },
  { path: "RewardsTable", component: NotionTable },
  { path: "Academy", component: Academy },

  //Rutas de NPM
  { path: "Academy/npm", component: NpmHome },
  { path: "Academy/IntroToNpm", component: IntroToNpm },
  { path: "Academy/NpmCommands", component: NpmCommandsPage },
  { path: "Academy/PackageJson", component: PackageJson },
  { path: "Academy/GlobalVsLocal", component: GlobalVsLocal },
  { path: "Academy/NpmTesting", component: NpmTesting },
  { path: "Academy/NpmAdvanced", component: NpmAdvanced },

  //Rutas de Python
  { path: "Academy/Python", component: PythonHome },
  { path: "Academy/IntroToPython", component: IntroToPython },
  { path: "Academy/PythonSyntax", component: PythonSyntax },
  { path: "Academy/PythonAdvanced", component: PythonAdvanced },

  //Rutas de Pandas
  { path: "Academy/Pandas", component: PandasHome },
  { path: "/Academy/PandasIntro", component: PandasIntro },
  { path: "/Academy/DataFrames", component: DataFrames },
  { path: "/Academy/AdvancedPandas", component: AdvancedPandas },

  //Rutas de HomeGames
  { path: "/HomeGames", component: HomeGames },
  //Rutas de MemoryMatchGame
  { path: "/MemoryMatchGame", component: MemoryMatchGame },
  //Rutas de LogicSwitchGame
  { path: "/LogicSwitchGame", component: LogicSwitchGame },
  //Rutas de LogicSwitchGame
  { path: "/ConnectNumbersGame", component: ConnectNumbersGame },

  //Rutas de Market
  { path: "/Marketplace", component: MarketHome },

  //Rutas del gestor de tareas
  { path: "/Heracles", component: TasksPage },

  // Nuevas rutas de OAuth2
  { path: "/login", component: OAuthLogin },
  { path: "/callback", component: OAuthCallback },
  { path: "/profile", component: ProfilePage },

  { path: "*", component: NotFound }, // Ruta para 404
];

export default routes;
