import React from "react";
import { Helmet } from "react-helmet";

const topCommands = [
  {
    command: "install",
    description: "Instala paquetes y dependencias en tu proyecto.",
    why: "Es la base para agregar bibliotecas necesarias en tus proyectos.",
    example: "npm install express",
  },
  {
    command: "start",
    description: "Inicia el servidor de desarrollo definido en package.json.",
    why: "Es el comando más común para iniciar aplicaciones durante el desarrollo.",
    example: "npm start",
  },
  {
    command: "uninstall",
    description: "Elimina paquetes instalados.",
    why: "Mantén limpio tu proyecto al eliminar dependencias no utilizadas.",
    example: "npm uninstall lodash",
  },
  {
    command: "update",
    description: "Actualiza paquetes instalados a la última versión compatible.",
    why: "Mantén tus dependencias actualizadas para evitar problemas.",
    example: "npm update",
  },
  {
    command: "init",
    description: "Crea un archivo package.json interactivo.",
    why: "Es el primer paso para iniciar un proyecto con npm.",
    example: "npm init",
  },
  {
    command: "audit",
    description: "Detecta y soluciona vulnerabilidades en tus dependencias.",
    why: "Asegúrate de que tu proyecto esté protegido contra problemas de seguridad.",
    example: "npm audit && npm audit fix",
  },
  {
    command: "run",
    description: "Ejecuta scripts definidos en tu package.json.",
    why: "Automatiza tareas como pruebas, compilación o despliegue.",
    example: "npm run build",
  },
  {
    command: "test",
    description: "Ejecuta las pruebas definidas en package.json.",
    why: "Verifica la calidad de tu código con un comando sencillo.",
    example: "npm test",
  },
  {
    command: "publish",
    description: "Publica tu paquete en el registro de npm.",
    why: "Comparte tu código con la comunidad o en un registro privado.",
    example: "npm publish",
  },
  {
    command: "doctor",
    description: "Diagnostica problemas en tu entorno npm.",
    why: "Detecta y soluciona problemas que afectan el rendimiento o la instalación.",
    example: "npm doctor",
  },
];

const TopNpmCommandsPage = () => {
  return (
    <>
      {/* SEO Mejorado */}
      <Helmet>
        <title>10 Comandos Imprescindibles de npm | Aprende npm Rápido</title>
        <meta
          name="description"
          content="Descubre los 10 comandos más importantes de npm que todo desarrollador debe conocer para gestionar proyectos Node.js."
        />
      </Helmet>

      <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
        <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
          10 Comandos Imprescindibles de npm
        </h1>
        <p style={{ textAlign: "center", fontSize: "1.1rem", marginBottom: "30px" }}>
          Si usas npm, estos son los comandos esenciales que necesitas conocer para gestionar tus
          proyectos como un profesional.
        </p>
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {topCommands.map((cmd, index) => (
            <li
              key={cmd.command}
              style={{
                marginBottom: "20px",
                borderBottom: "1px solid #ddd",
                paddingBottom: "20px",
              }}
            >
              <h2 style={{ fontSize: "1.5rem", color: "#007acc" }}>
                {index + 1}. {cmd.command}
              </h2>
              <p>
                <strong>Descripción:</strong> {cmd.description}
              </p>
              <p>
                <strong>¿Por qué es importante?</strong> {cmd.why}
              </p>
              <p>
                <strong>Ejemplo:</strong> <code>{cmd.example}</code>
              </p>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default TopNpmCommandsPage;
