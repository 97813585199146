import React from "react";
import { Link } from "react-router-dom";
import pythonPath from "./PythonPath.json"; // Importamos el archivo JSON

const PythonHome = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#1F2937", // Fondo oscuro
        color: "#E5E7EB", // Texto claro
        padding: "50px 20px",
        textAlign: "center",
      }}
    >
      <h1 style={{ fontSize: "3rem", marginBottom: "30px", color: "#E5E7EB" }}>
        Curso de Python
      </h1>
      <p style={{ fontSize: "1.2rem", marginBottom: "40px", color: "#D1D5DB" }}>
        Explora los temas del curso y mejora tu dominio de Python.
      </p>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "25px",
        }}
      >
        {pythonPath.map((section) => (
          <Link
            to={section.path} // Ruta dinámica desde el JSON
            key={section.id}
            style={{
              backgroundColor: "#374151", // Fondo de la tarjeta
              padding: "20px",
              borderRadius: "10px",
              width: "280px",
              textDecoration: "none",
              color: "#E5E7EB",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
              transition: "transform 0.3s, box-shadow 0.3s",
              textAlign: "left",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "translateY(-5px)";
              e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.4)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "translateY(0)";
              e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.2)";
            }}
            aria-label={`Explora la sección: ${section.name}`}
          >
            <h2
              style={{
                fontSize: "1.5rem",
                marginBottom: "10px",
                color: "#8A2BE2", // Título morado vibrante
              }}
            >
              {section.name}
            </h2>
            <p
              style={{ fontSize: "1rem", lineHeight: "1.5", color: "#D1D5DB" }}
            >
              {section.description}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default PythonHome;
