import React from "react";

const MarketItem = ({ item, onImageClick }) => {
  return (
    <div
      style={{
        border: "1px solid #ccc",
        borderRadius: "8px",
        padding: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s, box-shadow 0.3s",
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = "scale(1.05)";
        e.currentTarget.style.boxShadow = "0 6px 12px rgba(0, 0, 0, 0.2)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = "scale(1)";
        e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
      }}
    >
      <div
        style={{
          overflow: "hidden",
          borderRadius: "8px 8px 0 0",
          position: "relative",
        }}
      >
        <img
          src={item.image}
          alt={item.title}
          style={{
            width: "100%",
            height: "150px",
            objectFit: "cover",
            transition: "transform 0.3s",
            cursor: "pointer",
          }}
          onClick={onImageClick}
          onMouseOver={(e) => {
            e.currentTarget.style.transform = "scale(1.1)";
          }}
          onMouseOut={(e) => {
            e.currentTarget.style.transform = "scale(1)";
          }}
        />
      </div>
      <h2 style={{ fontSize: "18px", margin: "10px 0" }}>{item.title}</h2>
      <p style={{ fontSize: "14px", color: "#555" }}>{item.description}</p>
      <p
        style={{
          fontWeight: "bold",
          color: item.available ? "green" : "red",
        }}
      >
        {item.available ? "Disponible" : "No disponible"}
      </p>
    </div>
  );
};

export default MarketItem;
