import React from "react";
import { Helmet } from "react-helmet";

const GlobalVsLocal = () => {
  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#1F2937", // Fondo oscuro
        color: "#E5E7EB", // Texto claro
        minHeight: "100vh",
        lineHeight: "1.6",
      }}
    >
      {/* Helmet para SEO */}
      <Helmet>
        <title>Paquetes globales vs locales - NPM</title>
        <meta
          name="description"
          content="Aprende la diferencia entre paquetes globales y locales en NPM, y cuándo usar cada opción para tus proyectos Node.js."
        />
      </Helmet>

      {/* Encabezado principal */}
      <h1 style={{ fontSize: "2.5rem", marginBottom: "20px", color: "#8A2BE2" }}>
        Paquetes globales vs locales
      </h1>

      {/* Párrafos introductorios */}
      <p style={{ fontSize: "1.2rem", marginBottom: "15px" }}>
        NPM permite instalar paquetes de forma local o global.
      </p>
      <p style={{ fontSize: "1.2rem", marginBottom: "30px" }}>
        Conocer cuándo y cómo usar cada opción te ahorrará muchos problemas.
      </p>

      {/* Lista de diferencias */}
      <h2 style={{ fontSize: "2rem", marginBottom: "10px", color: "#E5E7EB" }}>
        Comparativa entre paquetes globales y locales
      </h2>
      <ul style={{ fontSize: "1.1rem", marginLeft: "20px" }}>
        <li style={{ marginBottom: "10px" }}>
          <b>Paquetes locales:</b> Se instalan en la carpeta <code>node_modules</code> del proyecto actual.
          <ul>
            <li>Comando: <code>npm install [paquete]</code></li>
            <li>Útiles para dependencias específicas de un proyecto.</li>
          </ul>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <b>Paquetes globales:</b> Se instalan en el sistema y están disponibles para todos los proyectos.
          <ul>
            <li>Comando: <code>npm install -g [paquete]</code></li>
            <li>Útiles para herramientas de línea de comandos (CLI).</li>
          </ul>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <b>Rutas de instalación:</b> 
          <ul>
            <li>Local: <code>./node_modules</code></li>
            <li>Global: Depende del sistema (ej. <code>/usr/local/lib/node_modules</code>)</li>
          </ul>
        </li>
      </ul>

      {/* Tabla comparativa */}
      <h2 style={{ fontSize: "2rem", marginBottom: "10px", color: "#E5E7EB" }}>
        Tabla comparativa
      </h2>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginTop: "20px",
          color: "#D1D5DB",
        }}
      >
        <thead>
          <tr>
            <th style={{ borderBottom: "2px solid #8A2BE2", padding: "10px" }}>
              Característica
            </th>
            <th style={{ borderBottom: "2px solid #8A2BE2", padding: "10px" }}>
              Paquetes Locales
            </th>
            <th style={{ borderBottom: "2px solid #8A2BE2", padding: "10px" }}>
              Paquetes Globales
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ borderBottom: "1px solid #374151", padding: "10px" }}>
              Comando de instalación
            </td>
            <td style={{ borderBottom: "1px solid #374151", padding: "10px" }}>
              <code>npm install [paquete]</code>
            </td>
            <td style={{ borderBottom: "1px solid #374151", padding: "10px" }}>
              <code>npm install -g [paquete]</code>
            </td>
          </tr>
          <tr>
            <td style={{ borderBottom: "1px solid #374151", padding: "10px" }}>
              Ubicación
            </td>
            <td style={{ borderBottom: "1px solid #374151", padding: "10px" }}>
              Proyecto local (<code>./node_modules</code>)
            </td>
            <td style={{ borderBottom: "1px solid #374151", padding: "10px" }}>
              Sistema global (<code>/usr/local</code>)
            </td>
          </tr>
          <tr>
            <td style={{ padding: "10px" }}>Uso</td>
            <td style={{ padding: "10px" }}>
              Dependencias específicas del proyecto
            </td>
            <td style={{ padding: "10px" }}>
              Herramientas CLI accesibles en todo el sistema
            </td>
          </tr>
        </tbody>
      </table>

      {/* Conclusión */}
      <p style={{ marginTop: "20px", fontSize: "1.1rem" }}>
        Entender cuándo usar paquetes locales o globales te ayudará a mantener
        tus proyectos organizados y eficientes.
      </p>
    </div>
  );
};

export default GlobalVsLocal;
