import React, { useEffect, useState } from "react";

const cleanDescription = (name, description) => {
  // Verifica si la descripción comienza con el nombre seguido de un espacio
  const prefix = `${name} `;
  if (description.startsWith(prefix)) {
    return description.slice(prefix.length); // Quita el nombre y el espacio
  }
  return description;
};

const TeamOverview = () => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Cargar el JSON desde la carpeta public
  useEffect(() => {
    fetch("/teamMembers.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error al cargar el JSON");
        }
        return response.json();
      })
      .then((data) => {
        setTeamMembers(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setError(err);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Cargando miembros del equipo...</p>;
  }

  if (error) {
    return <p>Error al cargar los datos: {error.message}</p>;
  }

  // Agrupar miembros por el rol
  const groupedMembers = teamMembers.reduce((groups, member) => {
    const { role } = member;
    if (!groups[role]) {
      groups[role] = [];
    }
    groups[role].push(member);
    return groups;
  }, {});

  return (
    <div className="p-8">
      {Object.entries(groupedMembers).map(([role, members]) => (
        <div key={role} className="mb-8">
          {/* Título del grupo según el rol */}
          <h2 className="text-2xl font-bold text-indigo-600 mb-4">{role}</h2>
          {/* Contenedor de miembros para este rol */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {members.map((member) => (
              <div
                key={member.id}
                className="p-4 border rounded shadow-lg hover:shadow-2xl transition duration-300"
              >
                <h3 className="text-xl font-medium mb-2">{member.name}</h3>
                <p className="text-gray-600">
                  {cleanDescription(member.name, member.description)}
                </p>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TeamOverview;
