import React, { useState, useEffect, useCallback } from "react";

function ExerciseTimer({ exercises, restTime, onRestart }) {
  const [currentExerciseIndex, setCurrentExerciseIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(exercises[0].duration);
  const [isPaused, setIsPaused] = useState(false);
  const [isBreak, setIsBreak] = useState(false);
  const [audioPlayed, setAudioPlayed] = useState(false);

  const playAudio = (file) => {
    const audio = new Audio(`/audio/${file}.mp3`);
    audio.onerror = () => {
      console.error(`Error al cargar el archivo de audio: /audio/${file}.mp3`);
    };
    audio.play().catch((error) => {
      console.error("Error al reproducir el audio:", error);
    });
  };

  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  const skipExercise = () => {
    if (currentExerciseIndex < exercises.length - 1) {
      setCurrentExerciseIndex((prev) => prev + 1);
      setIsPaused(false);
      setTimeLeft(exercises[currentExerciseIndex + 1].duration);
      setAudioPlayed(false);
    } else {
      handleRoutineCompletion(); // Llamar a la rutina completada
    }
  };

  const handleRoutineCompletion = useCallback(() => {
    playAudio("rutina_completada");
    if (onRestart) {
      onRestart(); // Llamar la función de reinicio para volver al menú
    }
  }, [onRestart]);

  useEffect(() => {
    if (isPaused) return;

    if (
      !isBreak &&
      timeLeft === exercises[currentExerciseIndex].duration &&
      !audioPlayed
    ) {
      playAudio(exercises[currentExerciseIndex].name);
      setAudioPlayed(true);
    }

    if (timeLeft === 0) {
      if (isBreak) {
        setIsBreak(false);
        if (currentExerciseIndex < exercises.length - 1) {
          setCurrentExerciseIndex((prev) => prev + 1);
          setTimeLeft(exercises[currentExerciseIndex + 1].duration);
          setAudioPlayed(false);
        } else {
          handleRoutineCompletion(); // Llamar la función cuando la rutina esté completa
        }
      } else {
        setIsBreak(true);
        setTimeLeft(restTime);
        playAudio("Descanso_activo");
      }
    } else {
      const timer = setTimeout(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [
    timeLeft,
    isPaused,
    currentExerciseIndex,
    isBreak,
    audioPlayed,
    exercises,
    restTime,
    handleRoutineCompletion,
  ]);

  return (
    <div className="p-4 bg-white shadow-lg rounded-lg text-center mx-auto max-w-sm">
      <h2 className="text-2xl md:text-3xl font-semibold text-gray-800 mb-4">
        {isBreak ? "Descanso activo" : exercises[currentExerciseIndex].name}
      </h2>
      <p className="text-5xl md:text-6xl font-bold text-blue-600 mb-6">
        {timeLeft} <span className="text-lg md:text-2xl">segundos</span>
      </p>

      {!isBreak && currentExerciseIndex < exercises.length - 1 && (
        <p className="text-lg text-gray-600">
          Próximo: {exercises[currentExerciseIndex + 1].name}
        </p>
      )}

      <div className="mt-4 flex justify-center">
        <button
          onClick={togglePause}
          className="bg-yellow-500 text-white py-2 px-3 rounded-lg mr-2"
        >
          {isPaused ? "Reanudar" : "Pausar"}
        </button>
        <button
          onClick={skipExercise}
          className="bg-red-500 text-white py-2 px-3 rounded-lg"
        >
          Saltar
        </button>
      </div>
    </div>
  );
}

export default ExerciseTimer;
