import React from "react";
import { Helmet } from "react-helmet";

const PythonSyntax = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#1F2937",
        color: "#E5E7EB",
        padding: "50px 20px",
        lineHeight: "1.6",
      }}
    >
      {/* Helmet para SEO */}
      <Helmet>
        <title>Sintaxis de Python - Curso de Python</title>
        <meta
          name="description"
          content="Domina la sintaxis básica de Python, aprende sobre variables, estructuras de control y funciones con ejemplos claros y recursos adicionales."
        />
      </Helmet>

      {/* Título principal */}
      <h1 style={{ fontSize: "2.5rem", marginBottom: "20px", color: "#8A2BE2" }}>
        Sintaxis de Python
      </h1>

      {/* Introducción */}
      <p style={{ fontSize: "1.2rem", marginBottom: "20px" }}>
        Domina la sintaxis básica y los conceptos clave de Python. Este módulo
        te enseñará cómo escribir código limpio, eficiente y fácil de mantener,
        siguiendo las mejores prácticas del lenguaje.
      </p>

      {/* Contenido */}
      <h2 style={{ fontSize: "2rem", marginBottom: "10px" }}>Contenido:</h2>
      <ul style={{ fontSize: "1.1rem", marginLeft: "20px" }}>
        <li>
          <b>Variables y tipos de datos:</b>
          <p style={{ marginTop: "5px" }}>
            Python es un lenguaje dinámico donde no necesitas declarar el tipo
            de una variable. Algunos tipos comunes incluyen:
          </p>
          <pre
            style={{
              backgroundColor: "#374151",
              padding: "10px",
              borderRadius: "8px",
              overflowX: "auto",
              color: "#E5E7EB",
            }}
          >
            {`# Ejemplos de variables
nombre = "Juan"  # String
edad = 25        # Entero
altura = 1.75    # Flotante
es_programador = True  # Booleano`}
          </pre>
        </li>

        <li>
          <b>Estructuras de control: bucles y condicionales:</b>
          <p style={{ marginTop: "5px" }}>
            Python ofrece estructuras simples y legibles para tomar decisiones
            y repetir tareas:
          </p>
          <pre
            style={{
              backgroundColor: "#374151",
              padding: "10px",
              borderRadius: "8px",
              overflowX: "auto",
              color: "#E5E7EB",
            }}
          >
            {`# Condicionales
if edad >= 18:
    print("Eres mayor de edad")
else:
    print("Eres menor de edad")

# Bucle for
for i in range(5):
    print(f"Iteración: {i}")

# Bucle while
contador = 0
while contador < 3:
    print(f"Contador: {contador}")
    contador += 1`}
          </pre>
        </li>

        <li>
          <b>Funciones:</b>
          <p style={{ marginTop: "5px" }}>
            Las funciones en Python son bloques reutilizables de código que
            realizan tareas específicas. Puedes definir funciones con o sin
            parámetros:
          </p>
          <pre
            style={{
              backgroundColor: "#374151",
              padding: "10px",
              borderRadius: "8px",
              overflowX: "auto",
              color: "#E5E7EB",
            }}
          >
            {`# Definición de una función
def saludar(nombre):
    print(f"Hola, {nombre}!")

# Llamada a la función
saludar("Ana")`}
          </pre>
        </li>
      </ul>

      {/* Mensaje final */}
      <p style={{ marginTop: "20px", fontSize: "1.1rem" }}>
        Aprende las bases que necesitas para construir aplicaciones potentes y
        bien estructuradas. ¡Explora más y practica con los ejemplos!
      </p>
    </div>
  );
};

export default PythonSyntax;
