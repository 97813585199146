// src/components/Heracles/TaskCard.jsx
import React from 'react';
import { Trash2 } from 'lucide-react';

const priorityColors = {
  Baja: '#81c784',
  Media: '#ffb74d',
  Alta: '#e57373',
};

const TaskCard = ({ task, columnId, provided, snapshot, deleteTask, onClick }) => {
  const cardStyle = {
    userSelect: 'none',
    padding: 16,
    margin: '0 0 8px 0',
    minHeight: '50px',
    backgroundColor: snapshot.isDragging ? '#673ab7' : '#512da8',
    color: 'white',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    cursor: 'pointer',
    ...provided.draggableProps.style,
  };

  const deleteButtonStyle = {
    position: 'absolute',
    top: 4,
    right: 4,
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    color: '#fff',
  };

  const infoStyle = {
    fontSize: '12px',
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    opacity: 0.9,
  };

  const priorityIndicatorStyle = {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: priorityColors[task.priority] || '#bbb',
    marginRight: '6px',
  };

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={cardStyle}
      onClick={onClick} // ← usamos la prop onClick para que el padre abra el modal
    >
      <div>
        <strong>{task.title}</strong>
        {task.responsable && (
          <p style={{ fontSize: '12px', margin: '4px 0 0' }}>
            ({task.responsable})
          </p>
        )}
      </div>

      <div style={infoStyle}>
        {task.priority && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={priorityIndicatorStyle} />
            <span>{task.priority}</span>
          </div>
        )}
        {task.dueDate && <span>{task.dueDate}</span>}
      </div>

      <button
        style={deleteButtonStyle}
        onClick={(e) => {
          e.stopPropagation(); // evita que el click abra el modal
          deleteTask(columnId, task.id);
        }}
        title="Eliminar tarea"
      >
        <Trash2 size={18} />
      </button>
    </div>
  );
};

export default TaskCard;
