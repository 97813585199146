import React from "react";

const AdvancedPandas = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#1F2937", // Fondo oscuro
        color: "#E5E7EB",
        padding: "50px 20px",
        lineHeight: "1.8",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <h1
        style={{ fontSize: "2.8rem", marginBottom: "30px", color: "#8A2BE2" }}
      >
        Funciones avanzadas en Pandas
      </h1>
      <p style={{ fontSize: "1.2rem", marginBottom: "30px" }}>
        Pandas no solo es útil para operaciones básicas, sino que también
        incluye funciones avanzadas que pueden llevar tu análisis de datos al
        siguiente nivel. Aprende cómo manejar grandes volúmenes de datos,
        realizar transformaciones complejas y trabajar con fuentes externas como
        bases de datos y APIs.
      </p>

      <h2 style={{ fontSize: "2rem", marginBottom: "20px" }}>
        Aplicar funciones a datos
      </h2>
      <p style={{ fontSize: "1.2rem", marginBottom: "20px" }}>
        Una de las características más útiles de Pandas es la capacidad de
        aplicar funciones personalizadas a columnas o filas usando{" "}
        <code>apply()</code> y <code>map()</code>:
      </p>
      <pre
        style={{
          backgroundColor: "#111827",
          color: "#E5E7EB",
          padding: "20px",
          borderRadius: "5px",
          overflowX: "auto",
          fontSize: "1rem",
        }}
      >
        <code>
          {`import pandas as pd

# Crear un DataFrame de ejemplo
data = {
  'Nombre': ['Ana', 'Luis', 'Pedro'],
  'Edad': [25, 30, 35]
}
df = pd.DataFrame(data)

# Aplicar una transformación con apply
df['Edad en meses'] = df['Edad'].apply(lambda x: x * 12)
print(df)`}
        </code>
      </pre>

      <h2 style={{ fontSize: "2rem", marginBottom: "20px" }}>
        Manejo de datos faltantes
      </h2>
      <p style={{ fontSize: "1.2rem", marginBottom: "20px" }}>
        Los datos faltantes pueden causar problemas en los análisis. Pandas
        ofrece herramientas para identificarlos y tratarlos de manera efectiva:
      </p>
      <pre
        style={{
          backgroundColor: "#111827",
          color: "#E5E7EB",
          padding: "20px",
          borderRadius: "5px",
          overflowX: "auto",
          fontSize: "1rem",
        }}
      >
        <code>
          {`# Identificar valores faltantes
df['Ciudad'] = ['Madrid', None, 'Valencia']
print(df.isnull())

# Reemplazar valores faltantes
df['Ciudad'].fillna('Desconocido', inplace=True)
print(df)`}
        </code>
      </pre>

      <h2 style={{ fontSize: "2rem", marginBottom: "20px" }}>
        Optimización para grandes volúmenes de datos
      </h2>
      <p style={{ fontSize: "1.2rem", marginBottom: "20px" }}>
        Trabajar con grandes conjuntos de datos puede ser lento si no se
        optimiza correctamente. Algunas técnicas incluyen:
      </p>
      <ul
        style={{
          fontSize: "1.2rem",
          marginBottom: "30px",
          paddingLeft: "20px",
          listStyleType: "circle",
        }}
      >
        <li>
          Usar tipos de datos más ligeros con <code>astype()</code>.
        </li>
        <li>
          Procesar datos en fragmentos (<code>chunksize</code>) al leer archivos
          grandes.
        </li>
        <li>
          Utilizar herramientas externas como <code>Dask</code> para
          procesamiento paralelo.
        </li>
      </ul>

      <h2 style={{ fontSize: "2rem", marginBottom: "20px" }}>
        Conexión con herramientas externas
      </h2>
      <p style={{ fontSize: "1.2rem", marginBottom: "20px" }}>
        Pandas facilita la interacción con bases de datos y APIs externas. Por
        ejemplo, puedes usar <code>read_sql()</code> para leer datos
        directamente de SQL:
      </p>
      <pre
        style={{
          backgroundColor: "#111827",
          color: "#E5E7EB",
          padding: "20px",
          borderRadius: "5px",
          overflowX: "auto",
          fontSize: "1rem",
        }}
      >
        <code>
          {`from sqlalchemy import create_engine

# Crear una conexión a una base de datos SQL
engine = create_engine('sqlite:///:memory:')

# Leer datos desde SQL
df = pd.read_sql('SELECT * FROM tabla', con=engine)
print(df)`}
        </code>
      </pre>

      <h2 style={{ fontSize: "2rem", marginBottom: "20px" }}>
        Recursos adicionales
      </h2>
      <ul
        style={{
          fontSize: "1.2rem",
          paddingLeft: "20px",
          listStyleType: "disc",
        }}
      >
        <li>
          <a
            href="https://pandas.pydata.org/docs/user_guide/advanced.html"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#8A2BE2" }}
          >
            Guía avanzada de Pandas
          </a>
        </li>
        <li>
          <a
            href="https://realpython.com/fast-flexible-pandas/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#8A2BE2" }}
          >
            Optimización de Pandas en Real Python
          </a>
        </li>
        <li>
          <a
            href="https://towardsdatascience.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#8A2BE2" }}
          >
            Tutoriales en Towards Data Science
          </a>
        </li>
      </ul>
    </div>
  );
};

export default AdvancedPandas;
