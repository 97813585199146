import React from "react";
import { useInView } from "react-intersection-observer";
import {
  Clock,
  Smartphone,
  Beaker,
  Music,
  Gamepad,
  TrendingUp,
} from "lucide-react";

const timelineData = [
  {
    year: "2022",
    event: "Inicio del proyecto",
    icon: Clock,
    detail:
      "Responder a la creciente demanda de espacios para gamers y creadores de contenido.",
    background: "bg-gradient-to-r from-indigo-500 to-purple-500",
  },
  {
    year: "2022",
    event: "Formación del equipo de Clash Royale",
    icon: Smartphone,
    detail:
      "Fomentar la competitividad y el trabajo en equipo en juegos móviles populares.",
    background: "bg-gradient-to-r from-yellow-500 to-orange-500",
  },
  {
    year: "2023",
    event: "Creación del laboratorio del club",
    icon: Beaker,
    detail:
      "Proporcionar un espacio de innovación y experimentación para proyectos tecnológicos.",
    background: "bg-gradient-to-r from-green-500 to-teal-500",
  },
  {
    year: "2024",
    event: "Lanzamiento del sello musical",
    icon: Music,
    detail:
      "Apoyar el talento musical local y ofrecer una plataforma para artistas emergentes.",
    background: "bg-gradient-to-r from-pink-500 to-red-500",
  },
  {
    year: "Próximamente",
    event: "Apertura del equipo de Brawl Stars",
    icon: Gamepad,
    detail:
      "Expandir nuestra presencia en la escena competitiva de juegos móviles.",
    background: "bg-gradient-to-r from-blue-500 to-cyan-500",
  },
  {
    year: "2025",
    event: "Expansión futura",
    icon: TrendingUp,
    detail:
      "Ampliar nuestro alcance y servicios para satisfacer las necesidades cambiantes de la comunidad.",
    background: "bg-gradient-to-r from-gray-700 to-black",
  },
];

const TimelineItem = ({ item }) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div
      ref={ref}
      className={`flex flex-col md:flex-row items-center gap-4 transition-all duration-700 ease-out ${
        inView
          ? "opacity-100 translate-y-0 scale-100"
          : "opacity-0 translate-y-10 scale-95"
      }`}
    >
      {/* Icono */}
      <div
        className={`flex items-center justify-center w-16 h-16 rounded-full text-white shadow-lg animate-spin-slow ${item.background}`}
      >
        <item.icon className="w-8 h-8" />
      </div>

      {/* Contenido */}
      <div
        className={`p-6 rounded-lg shadow-lg max-w-md text-white ${item.background}`}
      >
        <h3 className="text-xl font-bold">{item.year}</h3>
        <h4 className="text-lg font-semibold mt-2">{item.event}</h4>
        <p className="text-sm mt-2">{item.detail}</p>
      </div>
    </div>
  );
};

const Timeline = () => {
  const scrollToNextSection = () => {
    const nextSection = document.querySelector(".timeline-container");
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="relative">
      {/* Flecha Invitación */}
      <div className="text-center py-8 text-white">
        <button
          onClick={scrollToNextSection}
          className="flex items-center justify-center w-16 h-16 mx-auto rounded-full bg-yellow-400 text-white shadow-lg animate-bounce"
        >
          <span className="text-2xl font-bold">↓</span>
        </button>
        <p className="text-lg mt-4">Desplázate para conocer nuestra historia</p>
      </div>

      {/* Línea de Tiempo */}
      <div className="py-10 bg-gray-900 timeline-container">
        <div className="max-w-4xl mx-auto flex flex-col gap-16">
          {timelineData.map((item, index) => (
            <TimelineItem key={index} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Timeline;
