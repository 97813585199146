// src/components/Heracles/TaskDetailModal.jsx
import React from 'react';
import { X, Megaphone } from 'lucide-react';

const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const modalContentStyle = {
  backgroundColor: '#2d2d2d',
  padding: '40px 20px 20px',
  borderRadius: '4px',
  width: '90%',
  maxWidth: '600px',
  color: '#fff',
  position: 'relative',
  boxShadow: '0 2px 10px rgba(0,0,0,0.5)',
};

const closeButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '10px',
  background: 'transparent',
  border: 'none',
  color: '#fff',
  cursor: 'pointer',
};

const notifyButtonStyle = {
  position: 'absolute',
  top: '10px',
  right: '50px',
  background: 'transparent',
  border: 'none',
  color: '#fff',
  cursor: 'pointer',
};

const TaskDetailModal = ({ task, onClose, onNotify, columnName }) => {
  if (!task) return null;

  const handleNotify = async () => {
    // 7 horas en ms:
    const sevenHoursInMs = 7 * 60 * 60 * 1000;

    if (task.lastNotifiedAt) {
      const elapsed = Date.now() - task.lastNotifiedAt;
      if (elapsed < sevenHoursInMs) {
        const remainingMs = sevenHoursInMs - elapsed;
        const remainingHours = (remainingMs / (60 * 60 * 1000)).toFixed(2);
        alert(
          `Aún no han pasado 7 horas desde la última notificación.\nFaltan ${remainingHours} horas.`
        );
        return;
      }
    }

    const webhookUrl = process.env.REACT_APP_DISCORD_WEBHOOK_HERACLES;
    if (!webhookUrl) {
      alert("Webhook no configurado");
      return;
    }

    // Incluir la columna (columnName) en el mensaje
    const message = {
      content:
        `**Tarea Notificada**\n` +
        `**Estado actual:** ${columnName}\n` +
        `**Título:** ${task.title}\n` +
        `**Descripción:** ${task.description}\n` +
        (task.responsable ? `**Responsable:** ${task.responsable}\n` : '') +
        (task.priority ? `**Prioridad:** ${task.priority}\n` : '') +
        (task.dueDate ? `**Fecha de vencimiento:** ${task.dueDate}\n` : ''),
    };

    try {
      const response = await fetch(webhookUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(message),
      });
      if (response.ok) {
        alert("Notificación enviada exitosamente");
        onNotify(task.id);
      } else {
        alert("Error al enviar la notificación");
      }
    } catch (error) {
      alert("Error al enviar la notificación: " + error.message);
    }
  };

  return (
    <div style={modalOverlayStyle} onClick={onClose}>
      <div style={modalContentStyle} onClick={(e) => e.stopPropagation()}>
        <button style={notifyButtonStyle} onClick={handleNotify} title="Notificar tarea">
          <Megaphone size={24} />
        </button>
        <button style={closeButtonStyle} onClick={onClose} title="Cerrar">
          <X size={24} />
        </button>

        <h2>{task.title}</h2>
        <p><strong>Descripción:</strong> {task.description}</p>
        {task.responsable && (
          <p><strong>Responsable:</strong> {task.responsable}</p>
        )}
        {task.priority && (
          <p><strong>Prioridad:</strong> {task.priority}</p>
        )}
        {task.dueDate && (
          <p><strong>Fecha de vencimiento:</strong> {task.dueDate}</p>
        )}

        {task.notificado && (
          <p style={{ color: 'lime', marginTop: '10px' }}>
            ¡Esta tarea ya fue notificada!
          </p>
        )}
      </div>
    </div>
  );
};

export default TaskDetailModal;
