import React, { useState, useEffect } from "react";
import CustomRoutineCreator from "./CustomRoutineCreator";
import ExerciseTimer from "./ExerciseTimer";
import ModeSelector from "./Utils/ModeSelector";
import SavedRoutines from "./Utils/SavedRoutines";
import modes from "./Utils/modes";

const ModeDetails = ({ mode, selectedMode }) => {
  if (!selectedMode) return null;
  return (
    <div className="mb-4 p-4 bg-gray-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
      <h3 className="text-lg font-semibold mb-2 text-yellow-300">
        {mode.name}
      </h3>
      <p className="text-base text-gray-200">{mode.description}</p>
    </div>
  );
};

function App() {
  const [selectedMode, setSelectedMode] = useState("");
  const [hasStarted, setHasStarted] = useState(false);
  const [customRoutine, setCustomRoutine] = useState(null);
  const [customRestTime, setCustomRestTime] = useState(15);
  const [savedRoutines, setSavedRoutines] = useState([]);

  // Cargar rutinas guardadas desde localStorage
  useEffect(() => {
    const storedRoutines = localStorage.getItem("savedRoutines");
    if (storedRoutines) {
      setSavedRoutines(JSON.parse(storedRoutines));
    }
  }, []);

  // Guardar rutinas en localStorage
  const saveRoutinesToLocalStorage = (routines) => {
    localStorage.setItem("savedRoutines", JSON.stringify(routines));
    setSavedRoutines(routines);
  };

  // Manejadores
  const handleModeChange = (e) => {
    setSelectedMode(e.target.value);
    setHasStarted(false);
  };

  const handleRoutineCreated = (routine, routineName, restTime) => {
    const newRoutine = { name: routineName, exercises: routine, restTime };
    const updatedRoutines = [...savedRoutines, newRoutine];
    saveRoutinesToLocalStorage(updatedRoutines);
    setCustomRoutine(routine);
    setCustomRestTime(restTime);
  };

  const deleteRoutine = (routineName) => {
    const updatedRoutines = savedRoutines.filter(
      (routine) => routine.name !== routineName
    );
    saveRoutinesToLocalStorage(updatedRoutines);
  };

  const handleRestart = () => {
    setSelectedMode("");
    setHasStarted(false);
    setCustomRoutine(null);
  };

  const handleStartClick = () => {
    if (selectedMode) {
      setHasStarted(true);
    } else {
      alert("Por favor, selecciona un modo de entrenamiento.");
    }
  };

  const loadRoutine = (routine) => {
    if (
      !routine.exercises ||
      routine.exercises.length === 0 ||
      !routine.restTime
    ) {
      alert(
        "La rutina guardada está incompleta o tiene un formato incorrecto."
      );
      return;
    }
    setCustomRoutine(routine.exercises);
    setCustomRestTime(routine.restTime);
    setHasStarted(true);
  };

  return (
    <div className="flex min-h-screen bg-gradient-to-br from-blue-900 via-gray-900 to-black text-gray-100">
      <div className="transition-all duration-300 flex-grow p-4 mx-auto max-w-4xl">
        <h1 className="text-3xl md:text-4xl font-bold text-center mb-6 text-green-400 drop-shadow-md">
          Entrenamiento
        </h1>

        {/* Crear rutina personalizada */}
        {!customRoutine && selectedMode === "custom" && (
          <CustomRoutineCreator onRoutineCreated={handleRoutineCreated} />
        )}

        {/* Selector de modo */}
        {!customRoutine && !hasStarted && (
          <ModeSelector
            selectedMode={selectedMode}
            modes={modes}
            handleModeChange={handleModeChange}
          />
        )}

        {/* Detalles del modo seleccionado */}
        {!hasStarted && selectedMode && (
          <ModeDetails mode={modes[selectedMode]} selectedMode={selectedMode} />
        )}

        {/* Rutinas guardadas */}
        {!hasStarted && savedRoutines.length > 0 && (
          <SavedRoutines
            routines={savedRoutines}
            loadRoutine={loadRoutine}
            deleteRoutine={deleteRoutine}
          />
        )}

        {/* Temporizador de ejercicios */}
        {hasStarted && (
          <ExerciseTimer
            exercises={customRoutine || modes[selectedMode].exercises}
            restTime={
              customRoutine ? customRestTime : modes[selectedMode].restTime
            }
            onRestart={handleRestart}
          />
        )}

        {/* Botón para iniciar */}
        {!hasStarted && (
          <div className="flex justify-center mt-8">
            <button
              onClick={handleStartClick}
              className="bg-green-500 hover:bg-green-600 text-white w-24 h-24 rounded-full flex items-center justify-center shadow-lg transform transition-all duration-300 hover:scale-110 text-lg font-bold"
            >
              Iniciar
            </button>
          </div>
        )}

        {/* Botón para reiniciar */}
        {(customRoutine || hasStarted) && (
          <button
            onClick={handleRestart}
            className="w-full bg-red-500 hover:bg-red-600 text-white py-3 px-4 rounded-lg mt-4 transition-all duration-300 transform hover:scale-105"
          >
            Reiniciar y volver al menú
          </button>
        )}
      </div>
    </div>
  );
}

export default App;
