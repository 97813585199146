import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaFolder } from "react-icons/fa";

const learningPaths = [
  {
    name: "NPM",
    path: "/academy/npm",
    description: "Explora NPM y sus comandos esenciales.",
    preview: "Temas: Introducción a NPM, Comandos básicos, package.json",
  },
  {
    name: "Python",
    path: "/academy/python",
    description: "Domina Python desde lo básico hasta lo avanzado.",
    preview: "Temas: Sintaxis, Funciones Avanzadas, Librerías Populares",
  },
  {
    name: "Pandas",
    path: "/academy/pandas",
    description: "Manipula y analiza datos con Pandas.",
    preview: "Temas: DataFrames, Operaciones Básicas, Funciones Avanzadas",
  },
];

const Academy = () => {
  const [hoveredPath, setHoveredPath] = useState(null); // Estado para rastrear la tarjeta seleccionada

  const containerStyle = {
    minHeight: "100vh",
    backgroundColor: "#1F2937",
    color: "#E5E7EB",
    padding: "50px 20px",
    textAlign: "center",
  };

  const tooltipStyle = {
    position: "absolute",
    backgroundColor: "#374151",
    color: "#E5E7EB",
    padding: "10px",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 10,
    maxWidth: "300px",
    textAlign: "left",
    fontSize: "0.9rem",
  };

  return (
    <div style={containerStyle}>
      <h1 style={{ fontSize: "2.8rem", marginBottom: "30px" }}>
        Fifty Academy Learning Paths
      </h1>
      <p style={{ fontSize: "1.3rem", marginBottom: "50px" }}>
        Selecciona un curso para explorar sus secciones y temario. Pasa el mouse
        por encima para ver una previsualización.
      </p>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "25px",
          flexWrap: "wrap",
          position: "relative",
        }}
      >
        {learningPaths.map((course) => (
          <div
            key={course.name}
            onMouseEnter={() => setHoveredPath(course.name)} // Activa el tooltip al pasar el mouse
            onMouseLeave={() => setHoveredPath(null)} // Desactiva el tooltip al salir
            style={{
              backgroundColor: "#374151",
              borderRadius: "10px",
              padding: "20px",
              textDecoration: "none",
              color: "#E5E7EB",
              textAlign: "center",
              width: "260px",
              transition: "transform 0.3s",
              position: "relative",
              cursor: "pointer",
            }}
          >
            <Link
              to={course.path}
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <FaFolder
                style={{
                  fontSize: "3.5rem",
                  color: "#8A2BE2",
                  marginBottom: "15px",
                }}
              />
              <h2 style={{ fontSize: "1.5rem", marginBottom: "10px" }}>
                {course.name}
              </h2>
              <p style={{ fontSize: "1rem", color: "#D1D5DB" }}>
                {course.description}
              </p>
            </Link>

            {/* Mostrar tooltip si está seleccionado */}
            {hoveredPath === course.name && (
              <div
                style={{
                  ...tooltipStyle,
                  top: "100%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  marginTop: "10px",
                }}
              >
                <strong>Previsualización:</strong>
                <p>{course.preview}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Academy;
