import React from "react";
import { Helmet } from "react-helmet";

const IntroToPython = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#1F2937",
        color: "#E5E7EB",
        padding: "50px 20px",
        lineHeight: "1.6",
      }}
    >
      {/* Helmet para SEO */}
      <Helmet>
        <title>Introducción a Python - Curso de Python</title>
        <meta
          name="description"
          content="Aprende los fundamentos de Python, cómo configurarlo y por qué es un lenguaje ideal para principiantes y desarrolladores avanzados."
        />
      </Helmet>

      {/* Título principal */}
      <h1 style={{ fontSize: "2.5rem", marginBottom: "20px", color: "#8A2BE2" }}>
        Introducción a Python
      </h1>

      {/* Introducción */}
      <p style={{ fontSize: "1.2rem", marginBottom: "20px" }}>
        Aprende los fundamentos de Python y cómo configurarlo. Python es un
        lenguaje versátil y fácil de aprender, ideal tanto para principiantes
        como para desarrolladores avanzados. Es ampliamente utilizado en campos
        como desarrollo web, ciencia de datos, inteligencia artificial y más.
      </p>

      {/* Contenido */}
      <h2 style={{ fontSize: "2rem", marginBottom: "10px" }}>Contenido:</h2>
      <ul style={{ fontSize: "1.1rem", marginLeft: "20px" }}>
        <li>
          <b>¿Qué es Python y por qué es tan popular?</b>
          <p style={{ marginTop: "5px" }}>
            Python es un lenguaje de programación de alto nivel que se enfoca en
            la legibilidad del código. Su popularidad se debe a su simplicidad y
            a la vasta cantidad de bibliotecas disponibles para diversas
            aplicaciones.
          </p>
        </li>
        <li>
          <b>Instalación de Python en tu sistema operativo</b>
          <p style={{ marginTop: "5px" }}>
            Visita la página oficial de Python para descargar la última versión:
            <a
              href="https://www.python.org/downloads/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#8A2BE2", marginLeft: "5px" }}
            >
              Python.org
            </a>
          </p>
          <p>
            Sigue las instrucciones específicas para tu sistema operativo
            (Windows, macOS o Linux) para configurar Python correctamente.
          </p>
        </li>
        <li>
          <b>Primeros pasos: Ejecutar tu primer programa en Python</b>
          <p style={{ marginTop: "5px" }}>
            Una vez instalado Python, abre una terminal e ingresa el siguiente
            comando para probar tu instalación:
          </p>
          <pre
            style={{
              backgroundColor: "#374151",
              padding: "10px",
              borderRadius: "8px",
              overflowX: "auto",
              color: "#E5E7EB",
            }}
          >
            python --version
          </pre>
          <p>Escribe tu primer programa en Python:</p>
          <pre
            style={{
              backgroundColor: "#374151",
              padding: "10px",
              borderRadius: "8px",
              overflowX: "auto",
              color: "#E5E7EB",
            }}
          >
            {`print("¡Hola, mundo!")`}
          </pre>
          <p>
            Guarda el archivo como <code>hola.py</code> y ejecútalo con:
          </p>
          <pre
            style={{
              backgroundColor: "#374151",
              padding: "10px",
              borderRadius: "8px",
              overflowX: "auto",
              color: "#E5E7EB",
            }}
          >
            python hola.py
          </pre>
        </li>
      </ul>

      {/* Mensaje final */}
      <p style={{ marginTop: "20px", fontSize: "1.1rem" }}>
        ¡Empieza ahora y descubre todo lo que Python puede hacer por ti!
      </p>
    </div>
  );
};

export default IntroToPython;
