const productData = [
  {
    id: 1,
    title: "Gorra / Cap",
    image: "/images/cap.png",
    description: "Ideal para los amantes de la moda urbana.",
    price: "$20",
    available: false,
  },
  {
    id: 2,
    title: "Buzo / Coverall",
    image: "/images/coverall.png",
    description: "Perfecto para mantenerte cómodo en días fríos.",
    price: "$35",
    available: false,
  },
  {
    id: 3,
    title: "Chaqueta / Jacket",
    image: "/images/jacket.webp",
    description: "Estilo y funcionalidad en una sola pieza.",
    price: "$50",
    available: false,
  },
  // Más productos
];

export default productData;
