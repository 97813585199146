import React from "react";
import { Link } from "react-router-dom";

export default function HomeGames() {
  const games = [
    {
      name: "Memory Match Game",
      path: "/MemoryMatchGame",
      description:
        "Pon a prueba tu memoria encontrando las parejas de cartas iguales.",
    },
    {
      name: "Logic Switch Game",
      path: "/LogicSwitchGame",
      description: "Enciende todas las luces en este puzzle de interruptores.",
    },
    {
      name: "Connect Numbers Game",
      path: "/ConnectNumbersGame",
      description: "Conecta los números en orden sin que las líneas se crucen.",
    },
  ];

  return (
    <div
      style={{
        padding: "50px",
        minHeight: "100vh",
        backgroundColor: "#1e1e2f",
        color: "#fff",
        fontFamily: "'Roboto', sans-serif",
      }}
    >
      <h1
        style={{
          textAlign: "center",
          fontSize: "2.5rem",
          marginBottom: "10px",
        }}
      >
        Fifty Games - Home
      </h1>
      <p
        style={{
          textAlign: "center",
          fontSize: "1.2rem",
          marginBottom: "40px",
        }}
      >
        Selecciona un juego para empezar a jugar.
      </p>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: "20px",
        }}
      >
        {games.map((game, index) => (
          <div
            key={index}
            style={{
              background: "#292940",
              borderRadius: "12px",
              padding: "20px",
              width: "250px",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
              transition: "transform 0.3s, box-shadow 0.3s",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              minHeight: "200px",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = "translateY(-5px)";
              e.currentTarget.style.boxShadow = "0 6px 15px rgba(0, 0, 0, 0.5)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = "translateY(0)";
              e.currentTarget.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.3)";
            }}
          >
            <h2 style={{ fontSize: "1.5rem", marginBottom: "10px" }}>
              {game.name}
            </h2>
            <p
              style={{
                fontSize: "1rem",
                marginBottom: "20px",
                color: "#ccc",
                flexGrow: 1,
              }}
            >
              {game.description}
            </p>
            <Link
              to={game.path}
              style={{
                display: "block",
                padding: "12px 20px",
                background: "linear-gradient(135deg, #6a11cb, #2575fc)",
                color: "#fff",
                textDecoration: "none",
                borderRadius: "6px",
                textAlign: "center",
                fontWeight: "bold",
                transition: "background 0.3s",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.background =
                  "linear-gradient(135deg, #2575fc, #6a11cb)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.background =
                  "linear-gradient(135deg, #6a11cb, #2575fc)";
              }}
            >
              Jugar
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
