// src/components/Heracles/TasksPage.jsx
import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CollapsibleTaskForm from './CollapsibleTaskForm';
import TaskCard from './TaskCard';
import TaskDetailModal from './TaskDetailModal';

const defaultData = {
  Pendiente: [
    {
      id: '1',
      title: 'Definir requerimientos',
      description: 'Recopilar requisitos del proyecto',
      responsable: 'Alice',
      priority: 'Alta',
      dueDate: '2025-06-01',
      notificado: false,
      lastNotifiedAt: null,
    },
    {
      id: '2',
      title: 'Revisar documentación',
      description: 'Verificar la documentación de las APIs',
      responsable: 'Bob',
      priority: 'Media',
      dueDate: '2025-06-05',
      notificado: false,
      lastNotifiedAt: null,
    },
  ],
  'En curso': [],
  'En revisión': [],
  Finalizada: [],
};

const TasksPage = () => {
  // 1. Estado de columnas
  const [columns, setColumns] = useState(() => {
    const saved = localStorage.getItem('tasksColumns');
    return saved ? JSON.parse(saved) : defaultData;
  });

  // 2. Para el modal de detalle
  const [selectedTask, setSelectedTask] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);

  // 3. Detectar si estamos en "mobile" (<768px de ancho)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // Al montar el componente, y cuando la ventana cambie de tamaño, comprobamos el ancho
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Persistir en localStorage
  useEffect(() => {
    localStorage.setItem('tasksColumns', JSON.stringify(columns));
  }, [columns]);

  // Agregar tarea
  const addTask = (title, description, responsable, priority, dueDate) => {
    const newTask = {
      id: Date.now().toString(),
      title,
      description,
      responsable,
      priority,
      dueDate,
      notificado: false,
      lastNotifiedAt: null,
    };
    setColumns((prev) => ({
      ...prev,
      Pendiente: [...prev.Pendiente, newTask],
    }));
  };

  // Eliminar tarea
  const deleteTask = (columnId, taskId) => {
    const newCol = columns[columnId].filter((t) => t.id !== taskId);
    setColumns({ ...columns, [columnId]: newCol });
  };

  // Drag & drop
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;

    const sourceArray = [...columns[source.droppableId]];
    const destArray = [...columns[destination.droppableId]];
    const [movedTask] = sourceArray.splice(source.index, 1);

    if (source.droppableId === destination.droppableId) {
      sourceArray.splice(destination.index, 0, movedTask);
      setColumns({
        ...columns,
        [source.droppableId]: sourceArray,
      });
    } else {
      destArray.splice(destination.index, 0, movedTask);
      setColumns({
        ...columns,
        [source.droppableId]: sourceArray,
        [destination.droppableId]: destArray,
      });
    }
  };

  // Borrar todas las tareas
  const handleClearTasks = () => {
    if (window.confirm('¿Estás seguro de borrar todas las tareas?')) {
      localStorage.removeItem('tasksColumns');
      setColumns(defaultData);
    }
  };

  // Exportar tareas
  const handleExportTasks = () => {
    const dataStr = JSON.stringify(columns, null, 2);
    const blob = new Blob([dataStr], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'tasks.json';
    a.click();
    URL.revokeObjectURL(url);
  };

  // Abrir/Cerrar modal
  const handleOpenModal = (colId, task) => {
    setSelectedColumn(colId);
    setSelectedTask(task);
  };
  const handleCloseModal = () => {
    setSelectedColumn(null);
    setSelectedTask(null);
  };

  // Marcar como notificada (y guardar la hora)
  const handleNotifyTask = (columnId, taskId) => {
    setColumns((prev) => {
      const copy = { ...prev };
      const arr = [...copy[columnId]];
      const idx = arr.findIndex((t) => t.id === taskId);
      if (idx !== -1) {
        arr[idx] = {
          ...arr[idx],
          notificado: true,
          lastNotifiedAt: Date.now(),
        };
      }
      copy[columnId] = arr;
      return copy;
    });
  };

  // 4. Estilos de la pizarra, condicional según sea mobile o no
  const boardContainerStyle = {
    display: 'flex',
    // Si es mobile, mostramos las columnas apiladas en vertical;
    // si no, en fila con scroll horizontal
    flexDirection: isMobile ? 'column' : 'row',
    overflowX: isMobile ? 'hidden' : 'auto',
    padding: 20,
    backgroundColor: '#1e1e1e',
    minHeight: '100vh',
  };

  // Tamaño mínimo de cada columna (puede ajustarse)
  const columnStyle = {
    margin: 8,
    flex: isMobile ? 'unset' : '0 0 auto', // en desktop, evito que se achiquen
  };

  return (
    <div>
      <CollapsibleTaskForm
        onAddTask={addTask}
        onClearTasks={handleClearTasks}
        onExportTasks={handleExportTasks}
      />

      {/* Modal: solo si hay tarea seleccionada */}
      {selectedTask && (
        <TaskDetailModal
          task={selectedTask}
          onClose={handleCloseModal}
          onNotify={(taskId) => handleNotifyTask(selectedColumn, taskId)}
          columnName={selectedColumn}
        />
      )}

      {/* Contenedor de columnas */}
      <div style={boardContainerStyle}>
        <DragDropContext onDragEnd={onDragEnd}>
          {Object.entries(columns).map(([colId, tasks]) => (
            <div key={colId} style={columnStyle}>
              <h2 style={{ textAlign: 'center', color: '#ce93d8' }}>{colId}</h2>
              <Droppable droppableId={colId}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      background: snapshot.isDraggingOver ? '#424242' : '#333',
                      padding: 8,
                      width: 250,
                      minHeight: 500,
                      borderRadius: 4,
                      boxShadow: '0 1px 3px rgba(0,0,0,0.5)',
                    }}
                  >
                    {tasks.map((task, index) => (
                      <Draggable key={task.id} draggableId={task.id} index={index}>
                        {(provided, snapshot) => (
                          <TaskCard
                            task={task}
                            columnId={colId}
                            provided={provided}
                            snapshot={snapshot}
                            deleteTask={deleteTask}
                            onClick={() => handleOpenModal(colId, task)}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </DragDropContext>
      </div>
    </div>
  );
};

export default TasksPage;
