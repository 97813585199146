import React, { useState, useRef } from "react";

function generateBoard(size) {
  const board = [];
  for (let i = 0; i < size; i++) {
    const row = [];
    for (let j = 0; j < size; j++) {
      const isOn = Math.random() > 0.5;
      row.push(isOn);
    }
    board.push(row);
  }
  return board;
}

function LogicSwitchGame() {
  const size = 5; // Tamaño del tablero 5x5
  const [board, setBoard] = useState(() => generateBoard(size));
  const [clickedCell, setClickedCell] = useState(null); // Para animación de click

  // Cargar el audio del click si deseas sonido.
  // Debes tener un archivo "click.mp3" en la misma carpeta.
  // Si no lo tienes, comenta estas 3 líneas:
  const clickSoundRef = useRef(null);

  const allOn = board.every((row) => row.every((light) => light === true));

  const totalLights = size * size;
  const lightsOnCount = board.reduce(
    (acc, row) => acc + row.filter((light) => light).length,
    0
  );
  const percentage = Math.floor((lightsOnCount / totalLights) * 100);

  const toggleLights = (x, y) => {
    setBoard((prevBoard) => {
      const newBoard = prevBoard.map((row) => [...row]);

      const flip = (i, j) => {
        if (i >= 0 && i < size && j >= 0 && j < size) {
          newBoard[i][j] = !newBoard[i][j];
        }
      };

      flip(x, y); // la luz misma
      flip(x - 1, y); // arriba
      flip(x + 1, y); // abajo
      flip(x, y - 1); // izquierda
      flip(x, y + 1); // derecha

      return newBoard;
    });

    // Animación de clic
    setClickedCell({ x, y });
    setTimeout(() => setClickedCell(null), 200);

    // Reproducir sonido si existe
    if (clickSoundRef.current) {
      clickSoundRef.current.currentTime = 0;
      clickSoundRef.current.play().catch(() => {});
    }
  };

  const restartGame = () => {
    setBoard(generateBoard(size));
  };

  const containerStyle = {
    textAlign: "center",
    fontFamily: "sans-serif",
    padding: "20px",
    color: "#fff", // texto blanco
    background: "linear-gradient(to bottom, #000428, #004e92)", // fondo tipo espacial
    minHeight: "100vh",
    position: "relative",
  };

  const gridStyle = {
    display: "grid",
    gridTemplateColumns: `repeat(${size}, 40px)`,
    gridGap: "5px",
    justifyContent: "center",
    marginTop: "20px",
  };

  const cellStyle = (isOn, i, j) => {
    const baseStyle = {
      width: "40px",
      height: "40px",
      background: isOn ? "#ffff66" : "#333",
      cursor: "pointer",
      borderRadius: "4px",
      border: "1px solid #555",
      transition: "transform 0.2s, box-shadow 0.2s",
    };

    // Efecto al hacer clic
    if (clickedCell && clickedCell.x === i && clickedCell.y === j) {
      baseStyle.boxShadow = "0 0 10px #fff";
      baseStyle.transform = "scale(1.1)";
    }

    return baseStyle;
  };

  const buttonStyle = {
    margin: "10px",
    padding: "5px 10px",
    cursor: "pointer",
    background: "#222",
    color: "#fff",
    border: "1px solid #444",
    borderRadius: "4px",
  };

  const progressBarContainer = {
    width: "200px",
    height: "20px",
    background: "#222",
    borderRadius: "10px",
    margin: "10px auto",
    overflow: "hidden",
    border: "1px solid #444",
  };

  const progressBar = {
    width: `${percentage}%`,
    height: "100%",
    background: "linear-gradient(to right, #00f260, #0575e6)",
    transition: "width 0.3s",
  };

  return (
    <div style={containerStyle}>
      <style>{`
        @keyframes fall {
          0% {
            transform: rotate(0deg) translateY(0);
          }
          100% {
            transform: rotate(360deg) translateY(100vh);
          }
        }
      `}</style>

      {/* Audio opcional, comentar si no se desea */}
      <audio ref={clickSoundRef} src="click.mp3" preload="auto"></audio>

      <h1>Panel de Energía de la Nave</h1>
      <p>
        Has accedido al panel de control de la nave. Cada luz representa un
        sector de energía. Haz clic en una luz para alterar su estado y el de
        sus vecinas. Tu objetivo: <strong>Encenderlas todas</strong>.
      </p>
      <button onClick={restartGame} style={buttonStyle}>
        Reiniciar
      </button>
      {allOn && (
        <div>
          <h2>¡Energía Restaurada!</h2>
          <p>Todos los sistemas vuelven a la normalidad. ¡Excelente trabajo!</p>
        </div>
      )}

      <div style={{ marginTop: "20px" }}>
        <p>
          Energía establecida en: {lightsOnCount}/{totalLights} ({percentage}%)
        </p>
        <div style={progressBarContainer}>
          <div style={progressBar}></div>
        </div>
      </div>

      <div style={gridStyle}>
        {board.map((row, i) =>
          row.map((isOn, j) => (
            <div
              key={`${i}-${j}`}
              style={cellStyle(isOn, i, j)}
              onClick={() => toggleLights(i, j)}
            ></div>
          ))
        )}
      </div>
    </div>
  );
}

export default LogicSwitchGame;
