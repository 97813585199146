import React, { useState } from "react";
import MarketItem from "./MarketItem";
import productData from "./productData";

const MarketHome = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [filter, setFilter] = useState("all");

  const filteredProducts = productData.filter((item) => {
    if (filter === "available") return item.available;
    if (filter === "unavailable") return !item.available;
    return true; // Mostrar todos los productos
  });

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
    <div style={{ padding: "20px" }}>
      {/* Filtro desplegable */}
      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          style={{
            padding: "10px",
            fontSize: "16px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            backgroundColor: "#f9f9f9",
            color: "#333",
            outline: "none",
            cursor: "pointer",
            transition: "all 0.3s ease",
          }}
        >
          <option value="all" style={{ color: "#333" }}>
            Todos
          </option>
          <option value="available" style={{ color: "#28a745" }}>
            Disponibles
          </option>
          <option value="unavailable" style={{ color: "#dc3545" }}>
            No disponibles
          </option>
        </select>
      </div>

      {/* Productos */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
          gap: "20px",
        }}
      >
        {filteredProducts.map((item) => (
          <MarketItem
            key={item.id}
            item={item}
            onImageClick={() => handleImageClick(item.image)}
          />
        ))}
      </div>

      {/* Modal para mostrar la imagen ampliada */}
      {selectedImage && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
          onClick={closeModal}
        >
          <img
            src={selectedImage}
            alt="Zoomed"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(255, 255, 255, 0.3)",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default MarketHome;
