// src/components/Heracles/CollapsibleTaskForm.jsx
import React, { useState } from 'react';
import CreateTaskForm from './CreateTaskForm';
import { ChevronDown, ChevronUp, Plus, Trash2, Download } from 'lucide-react';

const CollapsibleTaskForm = ({ onAddTask, onClearTasks, onExportTasks }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleForm = () => {
    setExpanded(prev => !prev);
  };

  // Estilos para la cabecera que incluye acciones
  const headerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#7e57c2', // violeta suave
    color: 'white',
    padding: '12px 20px',
    cursor: 'pointer',
    borderRadius: expanded ? '4px 4px 0 0' : '4px', // redondeado cuando está cerrado
  };

  // Contenedor para las acciones (botones) que se muestran en la cabecera
  const actionsContainerStyle = {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  };

  return (
    <div style={{ maxWidth: '600px', margin: 'auto', marginBottom: '20px', border: '1px solid #7e57c2', borderRadius: '4px' }}>
      <div style={headerStyle} onClick={toggleForm}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Plus size={18} />
          <span>Crear Tarea</span>
        </div>
        {/* Contenedor de acciones */}
        <div style={actionsContainerStyle} onClick={e => e.stopPropagation()}>
          <button
            onClick={onExportTasks}
            title="Exportar tareas"
            style={{
              background: 'transparent',
              border: 'none',
              color: 'white',
              cursor: 'pointer',
            }}
          >
            <Download size={18} />
          </button>
          <button
            onClick={onClearTasks}
            title="Borrar todas las tareas"
            style={{
              background: 'transparent',
              border: 'none',
              color: 'white',
              cursor: 'pointer',
            }}
          >
            <Trash2 size={18} />
          </button>
          {expanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </div>
      </div>
      {expanded && <CreateTaskForm onAddTask={onAddTask} />}
    </div>
  );
};

export default CollapsibleTaskForm;
