import React from "react";
import { Helmet } from "react-helmet";

const PackageJson = () => {
  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#1F2937", // Fondo oscuro
        color: "#E5E7EB", // Texto claro
        minHeight: "100vh",
        lineHeight: "1.6",
      }}
    >
      {/* Helmet para SEO */}
      <Helmet>
        <title>Entendiendo package.json - NPM</title>
        <meta
          name="description"
          content="Aprende cómo funciona el archivo package.json y las secciones más importantes como dependencies, devDependencies, scripts y versionado semántico."
        />
      </Helmet>

      {/* Encabezado principal */}
      <h1 style={{ fontSize: "2.5rem", marginBottom: "20px", color: "#8A2BE2" }}>
        Entendiendo package.json
      </h1>

      {/* Párrafos introductorios */}
      <p style={{ fontSize: "1.2rem", marginBottom: "15px" }}>
        El archivo <code>package.json</code> es el corazón de cualquier proyecto
        Node.js. Contiene metadatos, scripts y dependencias.
      </p>
      <p style={{ fontSize: "1.2rem", marginBottom: "30px" }}>
        Aprenderás cómo configurarlo correctamente.
      </p>

      {/* Lista de secciones */}
      <h2 style={{ fontSize: "2rem", marginBottom: "10px", color: "#E5E7EB" }}>
        Secciones importantes de package.json
      </h2>
      <ul style={{ fontSize: "1.1rem", marginLeft: "20px" }}>
        <li style={{ marginBottom: "10px" }}>
          <b>Sección dependencies:</b> Paquetes requeridos que son esenciales
          para tu aplicación.
        </li>
        <li style={{ marginBottom: "10px" }}>
          <b>Sección devDependencies:</b> Herramientas de desarrollo como
          linters o testing frameworks.
        </li>
        <li style={{ marginBottom: "10px" }}>
          <b>Scripts:</b> Ejecutar tareas personalizadas con{" "}
          <code>npm run [script]</code> (ejemplo: <code>npm run start</code>).
        </li>
        <li style={{ marginBottom: "10px" }}>
          <b>Versionado semántico (SemVer):</b> Controla versiones de paquetes
          utilizando la sintaxis <code>MAJOR.MINOR.PATCH</code>.
        </li>
      </ul>

      {/* Ejemplo de package.json */}
      <h2 style={{ fontSize: "2rem", marginBottom: "10px", color: "#E5E7EB" }}>
        Ejemplo básico de package.json
      </h2>
      <pre
        style={{
          backgroundColor: "#374151",
          padding: "15px",
          borderRadius: "8px",
          overflowX: "auto",
          color: "#E5E7EB",
        }}
      >
        {`{
  "name": "mi-proyecto",
  "version": "1.0.0",
  "description": "Este es un ejemplo de package.json",
  "main": "index.js",
  "scripts": {
    "start": "node index.js",
    "test": "jest"
  },
  "dependencies": {
    "express": "^4.17.1"
  },
  "devDependencies": {
    "jest": "^27.0.0"
  }
}`}
      </pre>

      {/* Conclusión */}
      <p style={{ marginTop: "20px", fontSize: "1.1rem" }}>
        Ahora comprendes cómo funciona el archivo <code>package.json</code> y
        cómo configurarlo. Esta herramienta es fundamental para manejar las
        dependencias y scripts en tus proyectos Node.js.
      </p>
    </div>
  );
};

export default PackageJson;
