import React from "react";
import { FaChevronDown } from "react-icons/fa";

function ModeSelector({ selectedMode, modes, handleModeChange }) {
    return (
        <div className="mb-4 relative">
            <label
                htmlFor="mode"
                className="block text-lg font-semibold text-white mb-2 hover:text-purple-400 transition-colors"
            >
                Selecciona el modo de entrenamiento:
            </label>
            <div className="relative">
                <select
                    id="mode"
                    value={selectedMode}
                    onChange={handleModeChange}
                    className="w-full p-3 border border-gray-500 rounded-lg 
                    focus:outline-none focus:ring-2 focus:ring-purple-500 
                    hover:border-purple-400 transition-all duration-200 
                    text-black appearance-none cursor-pointer bg-white 
                    hover:shadow-lg"
                >
                    <option value="" disabled className="text-gray-500">
                        Selecciona un modo
                    </option>
                    {Object.keys(modes).map((mode) => (
                        <option key={mode} value={mode} className="hover:bg-purple-100">
                            {modes[mode].name}
                        </option>
                    ))}
                </select>
                <FaChevronDown className="absolute right-3 top-1/2 transform -translate-y-1/2 
                pointer-events-none text-gray-500 transition-colors group-hover:text-purple-500" />
            </div>
        </div>
    );
}

export default ModeSelector;
